import type { HTMLAttributes, ReactNode } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { missingClass } from '../../utils/missingClass' // Assuming this utility checks for missing classes

/**
 * Base classes applied to all Heading variants.
 */
const baseClasses = 'whitespace-pre-wrap font-bold'

/**
 * Size variants for the Heading component.
 */
const sizes: Record<string, string> = {
  display: 'text-display',
  heading: 'text-heading',
  lead: 'text-lead',
  copy: 'text-copy font-medium'
}

/**
 * Default variant values.
 */
const defaultVariants: Record<string, string> = {
  size: 'heading'
}

/**
 * Class Variance Authority configuration for Heading variants.
 */
const headingVariants = cva(baseClasses, {
  variants: {
    size: sizes
  },
  defaultVariants
})

/**
 * Props for the Heading component.
 */
export type HeadingProps = {
  /** The HTML element to render the heading as (e.g., 'h1', 'h2', etc.). Defaults to 'h2'. */
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  /** The content of the heading. */
  children: ReactNode
  /** Additional CSS classes for styling. */
  className?: string
} & VariantProps<typeof headingVariants> &
  HTMLAttributes<HTMLHeadingElement>

/**
 * Heading component that renders a styled heading element.
 *
 * @param {HeadingProps} props - Props for the Heading component.
 * @param {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'} [props.as='h2'] - The HTML element to render as.
 * @param {ReactNode} props.children - The content of the heading.
 * @param {'display' | 'heading' | 'lead' | 'copy'} [props.size='heading'] - The size variant of the heading.
 * @param {string} [props.className] - Additional CSS classes to apply.
 * @returns {JSX.Element} The rendered Heading component.
 */
export function Heading({
  as: Component = 'h2',
  children,
  className = '',
  size = 'heading',
  ...otherProps
}: HeadingProps): JSX.Element {
  /**
   * Combines variant classes with additional classes.
   * Ensures that 'whitespace-pre-wrap' is applied only if no 'whitespace-' class is present in className.
   */
  const combinedClasses = cn(
    headingVariants({ size }),
    missingClass(className, /^whitespace-/) && 'whitespace-pre-wrap',
    className
  )

  return (
    <Component {...otherProps} className={combinedClasses}>
      {children}
    </Component>
  )
}

Heading.displayName = 'Heading'
