'use client'

import { useEffect, useRef, useState } from 'react'

import localForage from 'localforage'

/**
 * Asynchronously retrieves an item from localForage and updates state.
 *
 * @template T The type of the stored item.
 * @param {string} key The key under which the item is stored.
 * @param {React.Dispatch<React.SetStateAction<T>>} setValue A state setter function to update the value in the component state.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading A state setter function to update the loading state in the component.
 */
const getStoredItem = async <T>(
  key: string,
  setValue: React.Dispatch<React.SetStateAction<T>>,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const storedValue = await localForage.getItem<T>(key)

    if (storedValue !== null) {
      setValue(storedValue)
    }
  } catch (error) {
    console.error(`Failed to retrieve item from LocalForage: ${error}`)
  } finally {
    if (setLoading) {
      setLoading(false)
    }
  }
}

/**
 * A custom hook to manage state with localForage, providing asynchronous persistence.
 *
 * @template T The type of the state to be managed.
 * @param {string} key The key to use with localForage for storing the item.
 * @param {T} initialValue The initial state value.
 * @param {boolean} enabled Whether to enable the hook's functionality.
 * @returns {[T, (newValue: T) => Promise<void>, boolean]} The current state, a function to update it, and a loading state.
 *
 * @example
 * const [value, setValue, isLoading] = useLocalForage('myKey', '', true);
 * // To update the value
 * setValue('newValue');
 */
export const useLocalForage = <T>(
  key: string,
  initialValue: T,
  enabled = true
): [T, (newValue: T | ((prevState: T) => T)) => Promise<void>, boolean] => {
  const [value, setValue] = useState<T>(initialValue)
  const [isLoading, setIsLoading] = useState(false)
  const loadingDelay = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!enabled) {
      setIsLoading(false)

      return
    }

    loadingDelay.current = setTimeout(() => {
      return setIsLoading(true)
    }, 200)

    getStoredItem<T>(key, setValue, () => {
      if (loadingDelay.current) {
        clearTimeout(loadingDelay.current)
        loadingDelay.current = null
      }

      setIsLoading(false)
    })

    return () => {
      if (loadingDelay.current) {
        clearTimeout(loadingDelay.current)
      }
    }
  }, [key, enabled])

  /**
   * Updates the stored value both in the state and in localForage.
   *
   * @param {T | ((prevState: T) => T)} newValue The new value to be stored or a function to generate it based on the current state.
   */
  const setStoredValue = async (newValue: T | ((prevState: T) => T)) => {
    try {
      const valueToStore = newValue instanceof Function ? newValue(value) : newValue

      if (enabled) {
        await localForage.setItem(key, valueToStore)
      }

      setValue(valueToStore)
    } catch (error) {
      console.error(`Failed to set item in LocalForage: ${error}`)
    }
  }

  return [value, setStoredValue, isLoading]
}
