'use client'

import { useEffect } from 'react'

import localForage from 'localforage'

/**
 * Custom hook to store data in localForage when the page visibility changes to hidden.
 *
 * @template T The type of the data to store.
 * @param {string} key - The key to use with localForage.
 * @param {T} data - The data to store.
 * @param {boolean} enabled - Whether to enable the hook.
 *
 * @example
 * usePageVisibility('myKey', myData, true);
 */
export const usePageVisibility = <T>(key: string, data: T, enabled = true) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const handleVisibilityChange = async () => {
      if (document.hidden) {
        await localForage.setItem(key, data)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [key, data, enabled])
}
