export const ImagePlaceholder = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
      <defs />
      <g fill="none" fill-rule="nonzero">
        <path fill="#EAEAEA" d="M0 0h800v800H0z" />
        <path
          fill="#FFF"
          d="M366.333 365.833c0 5.695-1.993 10.535-5.979 14.521-3.986 3.986-8.826 5.98-14.52 5.98-5.695 0-10.535-1.994-14.522-5.98-3.986-3.986-5.979-8.826-5.979-14.52 0-5.695 1.993-10.535 5.98-14.522 3.986-3.986 8.826-5.979 14.52-5.979 5.695 0 10.535 1.993 14.521 5.98 3.986 3.986 5.98 8.826 5.98 14.52zm109.334 41v47.834H325.333v-20.5L359.5 400l17.083 17.083 54.667-54.666 44.417 44.416zm10.25-75.166H315.083c-.925 0-1.726.338-2.402 1.014-.676.676-1.014 1.477-1.014 2.402v129.834c0 .925.338 1.726 1.014 2.402.676.676 1.477 1.014 2.402 1.014h170.834c.925 0 1.726-.338 2.402-1.014.676-.676 1.014-1.477 1.014-2.402V335.083c0-.925-.338-1.726-1.014-2.402-.676-.676-1.477-1.014-2.402-1.014zM503 335.083v129.834c0 4.698-1.673 8.72-5.018 12.065-3.346 3.345-7.367 5.018-12.065 5.018H315.083c-4.698 0-8.72-1.673-12.065-5.018-3.345-3.346-5.018-7.367-5.018-12.065V335.083c0-4.698 1.673-8.72 5.018-12.065 3.346-3.345 7.367-5.018 12.065-5.018h170.834c4.698 0 8.72 1.673 12.065 5.018 3.345 3.346 5.018 7.367 5.018 12.065z"
        />
      </g>
    </svg>
  )
}
