import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { ChevronRight, MoreHorizontal } from 'lucide-react'

import { cn } from '~/UI-Temp/src/utils/cn'

/**
 * Breadcrumb navigation component to enhance site navigation experience.
 *
 * @param {React.ComponentPropsWithoutRef<"nav"> & { separator?: React.ReactNode }} props Component props.
 * @param {React.Ref<HTMLElement>} ref Forwarded ref.
 */
const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<'nav'> & {
    separator?: React.ReactNode
  }
>(({ ...props }, ref) => {
  return <nav ref={ref} aria-label="breadcrumb" {...props} />
})
Breadcrumb.displayName = 'Breadcrumb'

/**
 * List container for breadcrumb items, ensuring proper layout and spacing.
 *
 * @param {React.ComponentPropsWithoutRef<"ol">} props Component props.
 * @param {React.Ref<HTMLOListElement>} ref Forwarded ref.
 */
const BreadcrumbList = React.forwardRef<HTMLOListElement, React.ComponentPropsWithoutRef<'ol'>>(
  ({ className, ...props }, ref) => {
    return (
      <ol
        ref={ref}
        className={cn(
          'flex flex-wrap items-center gap-1.5 break-words text-sm text-muted-foreground sm:gap-2.5',
          className
        )}
        {...props}
      />
    )
  }
)
BreadcrumbList.displayName = 'BreadcrumbList'

/**
 * Individual breadcrumb item, providing structure for the breadcrumb trail.
 *
 * @param {React.ComponentPropsWithoutRef<"li">} props Component props.
 * @param {React.Ref<HTMLLIElement>} ref Forwarded ref.
 */
const BreadcrumbItem = React.forwardRef<HTMLLIElement, React.ComponentPropsWithoutRef<'li'>>(
  ({ className, ...props }, ref) => {
    return (
      <li ref={ref} className={cn('inline-flex items-center gap-1.5', className)} {...props} />
    )
  }
)
BreadcrumbItem.displayName = 'BreadcrumbItem'

/**
 * Link component for breadcrumb items, supports being replaced with any component using `asChild`.
 *
 * @param {React.ComponentPropsWithoutRef<"a"> & { asChild?: boolean }} props Component props.
 * @param {React.Ref<HTMLAnchorElement>} ref Forwarded ref.
 */
const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'> & {
    asChild?: boolean
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : 'a'

  return (
    <Comp
      ref={ref}
      className={cn('transition-colors hover:text-foreground', className)}
      {...props}
    />
  )
})
BreadcrumbLink.displayName = 'BreadcrumbLink'

/**
 * Represents the current page in the breadcrumb trail as a non-interactive text element.
 *
 * @param {React.ComponentPropsWithoutRef<"span">} props Component props.
 * @param {React.Ref<HTMLSpanElement>} ref Forwarded ref.
 */
const BreadcrumbPage = React.forwardRef<HTMLSpanElement, React.ComponentPropsWithoutRef<'span'>>(
  ({ className, ...props }, ref) => {
    return (
      <span
        ref={ref}
        role="link"
        aria-disabled="true"
        aria-current="page"
        className={cn('font-normal text-foreground', className)}
        {...props}
      />
    )
  }
)
BreadcrumbPage.displayName = 'BreadcrumbPage'

/**
 * Custom separator for the breadcrumb items, defaults to a ChevronRight icon.
 *
 * @param {React.ComponentProps<"li">} props Component props.
 */
const BreadcrumbSeparator = ({ children, className, ...props }: React.ComponentProps<'li'>) => {
  return (
    <li
      role="presentation"
      aria-hidden="true"
      className={cn('[&>svg]:size-3.5', className)}
      {...props}
    >
      {children ?? <ChevronRight />}
    </li>
  )
}
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator'

/**
 * Ellipsis indicator for truncated breadcrumb paths, showing a MoreHorizontal icon.
 *
 * @param {React.ComponentProps<"span">} props Component props.
 */
const BreadcrumbEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => {
  return (
    <span
      role="presentation"
      aria-hidden="true"
      className={cn('flex h-9 w-9 items-center justify-center', className)}
      {...props}
    >
      <MoreHorizontal className="size-4" />
      <span className="sr-only">More</span>
    </span>
  )
}
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis'

export {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
}
