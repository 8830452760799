'use client'

import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, type VariantProps } from 'class-variance-authority'
import { Check, Minus } from 'lucide-react'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses =
  'peer h-4 w-4 shrink-0 group rounded-sm border focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'

const indeterminateClasses =
  'data-[state=indeterminate]:bg-primary data-[state=indeterminate]:text-primary-foreground'

const bothClasses = `${baseClasses} ${indeterminateClasses}`

const checkboxVariants = cva(bothClasses, {
  variants: {
    state: {
      checked: 'bg-primary text-primary-foreground',
      unchecked: 'bg-transparent text-transparent',
      indeterminate: 'bg-transparent text-transparent'
    }
  },
  defaultVariants: {
    state: 'unchecked'
  }
})

type CheckboxProps = ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
  VariantProps<typeof checkboxVariants>

export const Checkbox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, state = 'unchecked', ...props }, ref) => {
    const combinedClasses = cn(
      checkboxVariants({ state }),
      'ring-offset-background focus-visible:ring-2 focus-visible:ring-ring',
      className
    )

    return (
      <CheckboxPrimitive.Root ref={ref} className={combinedClasses} {...props}>
        <CheckboxPrimitive.Indicator
          className={cn('flex items-center justify-center text-current')}
        >
          <Check className="hidden size-4 group-data-[state=checked]:block" />
          <Minus className="hidden size-4 group-data-[state=indeterminate]:block" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    )
  }
)

Checkbox.displayName = CheckboxPrimitive.Root.displayName
