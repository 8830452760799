import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cn } from '~/UI-Temp/src/utils/cn'

export const TableBody = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => {
  return <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
})

TableBody.displayName = 'TableBody'
