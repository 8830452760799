'use client'

import { useEffect, useRef } from 'react'

/**
 * Custom hook to track if a component is mounted.
 *
 * @returns {object} - A React ref object with current value set to true if the component is mounted and false if it is unmounted.
 *
 * @example
 * const isMounted = useMounted();
 * useEffect(() => {
 *   if (isMounted.current) {
 *     // Perform an action only when the component is mounted
 *   }
 * }, []);
 */
export function useMounted() {
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return mountedRef
}
