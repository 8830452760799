'use client'

import type { DragEvent } from 'react'
import { useCallback, useState } from 'react'

type OnDropFunction = (files: FileList) => void

type IsValidFileFunction = (file: File) => boolean

type UseDragAndDropOptions = {
  onDrop: OnDropFunction
  isValidFile: IsValidFileFunction
}

type UseDragAndDropReturn = {
  isDragging: boolean
  isDragActive: boolean
  isInvalidFileText: string
  handleDragOver: (e: React.DragEvent<HTMLElement>) => void
  handleDragLeave: (e: React.DragEvent<HTMLElement>) => void
  handleDrop: (e: React.DragEvent<HTMLElement>) => void
  handleDragEnter: (e: React.DragEvent<HTMLElement>) => void
}
/**
 * Custom React hook to handle drag and drop functionality for file inputs.
 *
 * @param options - Object containing the onDrop callback and isValidFile function.
 * @returns An object containing the state and handlers for drag and drop.
 */
export const useDragAndDrop = ({
  onDrop,
  isValidFile
}: UseDragAndDropOptions): UseDragAndDropReturn => {
  const [isDragging, setIsDragging] = useState(false)
  const [isDragActive, setIsDragActive] = useState(false)
  const [isInvalidFileText, setIsInvalidFileText] = useState('')

  const handleDragEnter = useCallback((e: DragEvent<HTMLElement>) => {
    e.preventDefault()
    setIsDragging(true)
    setIsDragActive(true)
  }, [])

  const handleDragOver = useCallback(
    (e: DragEvent<HTMLElement>) => {
      e.preventDefault()

      if (e.dataTransfer.items.length > 0) {
        const allValid = Array.from(e.dataTransfer.items).every(item => {
          if (item.kind === 'file') {
            const file = item.getAsFile()

            return file !== null && isValidFile(file)
          }

          return false
        })
        setIsInvalidFileText(allValid ? '' : 'Invalid file(s)')
      }
    },
    [isValidFile]
  )

  const handleDragLeave = useCallback((e: DragEvent<HTMLElement>) => {
    e.preventDefault()
    setIsDragActive(false)
    setIsDragging(false)
    setIsInvalidFileText('')
  }, [])

  const handleDrop = useCallback(
    (e: DragEvent<HTMLElement>) => {
      e.preventDefault()
      setIsDragging(false)
      setIsDragActive(false)
      setIsInvalidFileText('')

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        onDrop(e.dataTransfer.files)
      }
    },
    [onDrop]
  )

  return {
    isDragging,
    isDragActive,
    isInvalidFileText,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleDragEnter
  }
}
