'use client'

import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

export const Popover = PopoverPrimitive.Root

export const PopoverTrigger = PopoverPrimitive.Trigger
export const PopoverArrow = PopoverPrimitive.Arrow
export const PopoverClose = PopoverPrimitive.Close
export const PopoverAnchor = PopoverPrimitive.Anchor

const baseClasses =
  'z-50 w-72 rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'

const popoverVariants = cva(baseClasses, {
  variants: {
    width: {
      default: 'w-72'
    }
  },
  defaultVariants: {
    width: 'default'
  }
})

type PopoverContentProps = {
  className?: string
  align?: 'start' | 'center' | 'end'
  sideOffset?: number
} & VariantProps<typeof popoverVariants> &
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>

/**
 * A customizable popover content component.
 *
 * @param {Object} props - The props for the PopoverContent component.
 * @param {string} [props.className] - Additional CSS classes to apply to the popover content.
 * @param {'start' | 'center' | 'end'} [props.align='center'] - The alignment of the popover content relative to the trigger. Can be 'start', 'center', or 'end'.
 * @param {number} [props.sideOffset=4] - The offset distance from the trigger element.
 * @param {VariantProps<typeof popoverVariants>} [props.width='default'] - The width variant of the popover content. Defaults to 'default'.
 * @param {ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>} [props.restProps] - Additional props to pass to the underlying PopoverPrimitive.Content component.
 * @param {ElementRef<typeof PopoverPrimitive.Content>} ref - The ref to be forwarded to the underlying PopoverPrimitive.Content component.
 *
 * @returns {JSX.Element} The rendered PopoverContent component.
 *
 * @example
 * // Basic usage
 * <Popover>
 *   <PopoverTrigger>Click me</PopoverTrigger>
 *   <PopoverContent>This is a popover</PopoverContent>
 * </Popover>
 *
 * @example
 * // With custom alignment and width
 * <Popover>
 *   <PopoverTrigger>Click me</PopoverTrigger>
 *   <PopoverContent align="start" width="w-96">
 *     This is a wide popover aligned to the start
 *   </PopoverContent>
 * </Popover>
 */
export const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>((props, ref) => {
  const { className, align = 'center', sideOffset = 4, width, ...restProps } = props

  const combinedClasses = cn(popoverVariants({ width }), className)

  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={combinedClasses}
        {...restProps}
      />
    </PopoverPrimitive.Portal>
  )
})

PopoverContent.displayName = PopoverPrimitive.Content.displayName
