'use client'

import type { ReactNode } from 'react'

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../Dialog'

type ModalProps = {
  isOpen: boolean
  title: string
  description: string
  onClose: () => void
  children?: ReactNode
}

export function ModalRadix(props: ModalProps) {
  const { isOpen, title, description, onClose, children } = props

  function onOpenChange(isOpen: boolean) {
    console.log('onOpenChange')

    if (!isOpen) {
      onClose()
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div>{children}</div>
      </DialogContent>
    </Dialog>
  )
}
