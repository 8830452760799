'use client'

import * as React from 'react'

import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = 'relative h-4 w-full overflow-hidden rounded-full'

const progressVariants = cva(baseClasses, {
  variants: {
    background: {
      secondary: 'bg-secondary',
      custom: 'bg-custom' // Add your custom background classes here
    }
  },
  defaultVariants: {
    background: 'secondary'
  }
})

type ProgressProps = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> &
  VariantProps<typeof progressVariants>

export const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, background = 'secondary', ...otherProps }, ref) => {
  const combinedClasses = cn(progressVariants({ background }), className)

  return (
    <ProgressPrimitive.Root ref={ref} className={combinedClasses} {...otherProps}>
      <ProgressPrimitive.Indicator
        className="size-full flex-1 bg-primary transition-all"
        style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  )
})
Progress.displayName = ProgressPrimitive.Root.displayName
