'use client'

import type { ChangeEvent, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { FaUpload } from 'react-icons/fa'

import { cn } from '~/UI-Temp/src/utils/cn'

import { FileDisplay } from './FileDisplay'
import { useDragAndDrop } from './useDragAndDrop'
import { useUpload, type FileWithPreview } from './useUpload'

export type FileUploaderProps = {
  onFileSelect?: (files: FileWithPreview[]) => void
  allowedFileTypes?: string[]
  maxFileSizeMB?: number
  dragAreaClassName?: string
  icon?: ReactNode
  dragActiveClassName?: string
  dragInactiveClassName?: string
  invalidFileClassName?: string
  allowedFormatsText?: string
  maxFileSizeText?: string
  dragAndDropText?: string
  browseText?: string
  invalidFileText?: string
  showFileDisplay?: boolean
  multiple?: boolean
}

/**
 * Validates a file based on allowed types and maximum size.
 * @param {File} file - The file to validate.
 * @param {string[]} allowedFileTypes - Array of allowed file types.
 * @param {number} maxFileSizeMB - Maximum file size in MB.
 * @returns {boolean} - True if the file is valid, false otherwise.
 */
const validateFile = (file: File, allowedFileTypes?: string[], maxFileSizeMB?: number): boolean => {
  if (!file) {
    return false
  }

  return (
    (!allowedFileTypes ||
      allowedFileTypes.some(type => {
        return file.type.startsWith(type)
      })) &&
    (!maxFileSizeMB || file.size <= maxFileSizeMB * 1024 * 1024)
  )
}

/**
 * Gets the class name for the drag area based on the current state.
 * @param {boolean} isDraggingOver - Whether a file is being dragged over the area.
 * @param {boolean} isInvalid - Whether the file is invalid.
 * @param {string} dragAreaClassName - Custom class name for the drag area.
 * @param {string} dragActiveClassName - Class name when drag is active.
 * @param {string} dragInactiveClassName - Class name when drag is inactive.
 * @param {string} invalidFileClassName - Class name for invalid files.
 * @returns {string} - The computed class name for the drag area.
 */
const getDragAreaClass = (
  isDraggingOver: boolean,
  isInvalid: boolean,
  dragAreaClassName?: string,
  dragActiveClassName?: string,
  dragInactiveClassName?: string,
  invalidFileClassName?: string
): string => {
  return cn(
    dragAreaClassName ||
      'border-2 border-dashed p-6 text-center cursor-pointer flex flex-col items-center justify-center rounded-lg',
    isDraggingOver ? dragActiveClassName : dragInactiveClassName,
    isInvalid ? invalidFileClassName : ''
  )
}

/**
 * FileUploader component for uploading files with drag and drop support.
 * @param {FileUploaderProps} props - The props for the component.
 * @returns {JSX.Element} - The rendered component.
 */
export const FileUploader = ({
  onFileSelect,
  allowedFileTypes,
  maxFileSizeMB,
  dragAreaClassName,
  icon = <FaUpload className="size-12 text-gray-400/50" />,
  dragActiveClassName = 'border-primary bg-primary/5',
  dragInactiveClassName = 'border-gray-200 bg-gray-50/50',
  invalidFileClassName = 'border-destructive bg-destructive/5',
  allowedFormatsText,
  maxFileSizeText,
  dragAndDropText = 'Click to upload or drag and drop files',
  browseText,
  invalidFileText = 'Invalid file(s)',
  showFileDisplay = true,
  multiple = true
}: FileUploaderProps): JSX.Element => {
  console.log('FileUploader')

  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  const { selectedFiles, error, warning, handleFiles, clearFiles, removeFile } = useUpload({
    allowedFileTypes,
    maxFileSizeMB,
    onFileSelect
  })

  const {
    isDragging,
    isDragActive,
    isInvalidFileText,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleDragEnter
  } = useDragAndDrop({
    onDrop: handleFiles,
    isValidFile: file => {
      return validateFile(file, allowedFileTypes, maxFileSizeMB)
    }
  })

  useEffect(() => {
    setIsDraggingOver(isDragging || isDragActive)
    setIsInvalid(!!isInvalidFileText)
  }, [isDragging, isDragActive, isInvalidFileText])

  const allowedFileTypesString = allowedFileTypes?.join(',') ?? ''

  const dragAreaClass = cn(
    'relative flex min-h-[200px] flex-col items-center justify-center rounded-lg border-2 border-dashed p-8 transition-colors',
    'hover:bg-accent/5 hover:border-accent',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
    dragAreaClassName,
    isDraggingOver ? dragActiveClassName : dragInactiveClassName,
    isInvalid ? invalidFileClassName : ''
  )

  const previewsContainerClass = cn(
    'grid gap-4',
    'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
  )

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('File input change event:', event)
    const { files } = event.target
    console.log('Files from event:', files)

    if (files) {
      handleFiles(files)
    }
  }

  const handleDragOverWrapper = (e: React.DragEvent<HTMLDivElement>) => {
    handleDragOver(e)
    setIsDraggingOver(true)
  }

  const handleDragLeaveWrapper = (e: React.DragEvent<HTMLDivElement>) => {
    handleDragLeave(e)
    setIsDraggingOver(false)
  }

  return (
    <div className="w-full">
      <div
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOverWrapper}
        onDragLeave={handleDragLeaveWrapper}
        onDrop={handleDrop}
        role="button"
        tabIndex={0}
        aria-label="File uploader"
        className="grid gap-4"
      >
        <input
          type="file"
          multiple={multiple}
          onChange={onFileInputChange}
          className="hidden"
          id="file-uploader-input"
          accept={allowedFileTypesString}
        />
        <label
          htmlFor="file-uploader-input"
          className={dragAreaClass}
        >
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            {icon}
            {isInvalid ? (
              <p className="text-sm font-medium text-destructive">{invalidFileText}</p>
            ) : (
              <>
                <div className="space-y-2">
                  <p className="text-sm font-medium text-foreground">
                    {dragAndDropText}
                  </p>
                  {(allowedFormatsText || maxFileSizeText) && (
                    <p className="text-xs text-muted-foreground">
                      {allowedFormatsText && `${allowedFormatsText} `}
                      {maxFileSizeText && `(Max: ${maxFileSizeText})`}
                    </p>
                  )}
                </div>
                {browseText && (
                  <p className="text-sm font-medium text-primary hover:text-primary/80">
                    {browseText}
                  </p>
                )}
              </>
            )}
          </div>
        </label>

        {showFileDisplay && selectedFiles.length > 0 && (
          <div className="space-y-4">
            <div className={previewsContainerClass}>
              {selectedFiles.map(file => (
                <FileDisplay key={file.id} file={file} removeFile={removeFile} />
              ))}
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={clearFiles}
                className="inline-flex items-center rounded-md bg-destructive/10 px-3 py-2 text-sm font-medium text-destructive hover:bg-destructive/20"
              >
                Clear files
              </button>
            </div>
          </div>
        )}

        {(error || warning) && (
          <div className="space-y-2">
            {error && (
              <div
                role="alert"
                aria-live="assertive"
                className="rounded-md border border-destructive/50 bg-destructive/10 p-3 text-sm text-destructive"
              >
                {error}
              </div>
            )}
            {warning && (
              <div
                role="alert"
                aria-live="assertive"
                className="rounded-md border border-warning/50 bg-warning/10 p-3 text-sm text-warning"
              >
                {warning}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
