import type { ReactNode } from 'react'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Heading } from '../../UI/Heading/Heading'

type PageHeaderProps = {
  children?: ReactNode
  className?: string
  heading?: string
  variant?: 'default' | 'blogPost' | 'allCollections'
  [key: string]: any
}

/**
 * A PageHeader component that allows you to easily create a header for a page with various styles.
 *
 * @param {PageHeaderProps} props - The component props
 * @param {ReactNode} [props.children] - The children to be rendered
 * @param {string} [props.className] - Additional class names to be added to the component
 * @param {string} [props.heading] - The heading of the header
 * @param {'default'|'blogPost'|'allCollections'} [props.variant='default'] - The variant of the header
 * @returns {JSX.Element} - The PageHeader component
 */
export function PageHeader({
  children,
  className,
  heading,
  variant = 'default',
  ...props
}: PageHeaderProps) {
  const variants: Record<string, string> = {
    default: 'grid w-full gap-8 p-6 py-8 md:p-8 lg:p-12 justify-items-start'
  }

  const styles = cn(variants[variant], className)

  return (
    <header {...props} className={styles}>
      {heading && (
        <Heading as="h1" size="heading" className="inline-block">
          {heading}
        </Heading>
      )}
      {children}
    </header>
  )
}
