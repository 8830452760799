import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cn } from '~/UI-Temp/src/utils/cn'

export const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => {
    return (
      <tr
        ref={ref}
        className={cn(
          'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
          className
        )}
        {...props}
      />
    )
  }
)

TableRow.displayName = 'TableRow'
