import type { ElementType, HTMLAttributes, ReactElement } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = 'bg-brand-300/10 rounded'

const skeletonVariants = cva(baseClasses, {
  variants: {
    size: {
      small: 'size-4',
      medium: 'size-6',
      large: 'size-8'
    },
    shape: {
      circle: 'rounded-full',
      square: 'rounded-md'
    },
    animated: {
      true: 'animate-pulse',
      false: ''
    }
  },
  defaultVariants: {
    size: 'medium',
    shape: 'square',
    animated: false
  }
})

export type SkeletonProps = {
  as?: ElementType
  width?: string
  height?: string
  className?: string
} & VariantProps<typeof skeletonVariants> &
  HTMLAttributes<HTMLElement>

/**
 * A shared component that renders a skeleton element.
 *
 * @param {SkeletonProps} props - The component props
 * @param {ElementType} [props.as='div'] - The element type to render the skeleton as
 * @param {string} [props.width] - The width of the skeleton element
 * @param {string} [props.height] - The height of the skeleton element
 * @param {string} [props.className] - Additional class names to be added to the component
 * @returns {ReactElement} - The Skeleton component
 */
export function Skeleton({
  as: Component = 'div',
  width,
  height,
  className,
  ...props
}: SkeletonProps): ReactElement {
  const combinedClasses = cn(skeletonVariants(props), className)

  return <Component {...props} style={{ width, height }} className={combinedClasses} />
}
