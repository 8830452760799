import type { HelperTextVariant } from './types'

/**
 * Generates the appropriate style class for the helper text based on its variant.
 *
 * @param variant - The variant of the helper text.
 *
 * @returns The style class for the helper text.
 */
export const getHelperTextStyle = (variant: HelperTextVariant) => {
  switch (variant) {
    case 'danger':
      return 'text-red-500'
    case 'alert':
      return 'text-yellow-500'
    case 'info':
      return 'text-blue-500'
    default:
      return 'text-gray-500'
  }
}
