'use client'

import { useEffect, useState } from 'react'

/**
 * Custom hook to debounce a value, typically a string.
 * This hook is intended for debouncing values and not function calls.
 *
 * @param {string} value - The value to debounce.
 * @param {number} delay - The debounce delay in milliseconds.
 * @returns {string} - The debounced value.
 *
 * @example
 * const debouncedSearchTerm = useDebounceValue(searchTerm, 500);
 */
export const useDebounceValue = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}
