'use client'

import { useEffect, useRef, useState } from 'react'

import type { UseRiveOptions, UseRiveParameters } from '@rive-app/react-canvas'
import { useRive } from '@rive-app/react-canvas'

type RiveProps = {
  className?: string
  playMethod?: 'hover' | 'hoverReverse' | 'click' | 'observer'
  parameters?: UseRiveParameters | undefined
  options?: Partial<UseRiveOptions> | undefined
}

/**
 * A component for rendering Rive animations with various play methods.
 *
 * @param {Object} props - The props for the Rive component.
 * @param {string} [props.className=''] - The CSS class name to apply to the component container.
 * @param {string} [props.playMethod='observer'] - The method to control the animation playback. Can be 'hover', 'hoverReverse', 'click', or 'observer'.
 * @param {UseRiveParameters} [props.parameters] - The parameters for the Rive animation.
 * @param {Partial<UseRiveOptions>} [props.options] - Additional options for the Rive animation.
 *
 * @example
 * // Using the "thinking" animation
 * <Rive
 *   parameters={{
 *     src: '/assets/rive/thinking-lottie.riv',
 *     autoplay: true,
 *   }}
 * />
 *
 * @example
 * // Using the "loading" animation with custom options
 * <Rive
 *   parameters={{
 *     src: '/assets/rive/loading-lottie.riv',
 *     autoplay: false,
 *   }}
 *   options={{
 *     loop: false,
 *     onLoad: () => console.log('Animation loaded'),
 *   }}
 *   playMethod="click"
 * />
 *
 * @example
 * // Using a custom animation with the "hover" play method
 * <Rive
 *   className="custom-animation"
 *   parameters={{
 *     src: '/path/to/custom/animation.riv',
 *     autoplay: false,
 *   }}
 *   playMethod="hover"
 * />
 *
 * @returns {JSX.Element} The rendered Rive component.
 */
export const Rive = ({
  className = '',
  playMethod = 'observer',
  parameters,
  options
}: RiveProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState(parameters?.autoplay ?? true)

  const effectiveParameters: UseRiveParameters = {
    src: '',
    autoplay: true,
    ...parameters
  }

  const { rive, RiveComponent } = useRive(effectiveParameters, options)

  useEffect(() => {
    if (playMethod !== 'observer' || !containerRef.current || !rive) {
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) {
          rive.play()
        } else {
          rive.pause()
        }
      },
      { rootMargin: '0px', threshold: 0.1 }
    )

    observer.observe(containerRef.current)

    return () => {
      return observer.disconnect()
    }
  }, [rive, playMethod])

  const handleMouseEnter = () => {
    return playMethod === 'hoverReverse' ? rive?.pause() : rive?.play()
  }

  const handleMouseLeave = () => {
    return playMethod === 'hoverReverse' ? rive?.play() : rive?.pause()
  }

  const handleClick = () => {
    if (!rive) {
      return
    }

    if (isPlaying) {
      rive.pause()
    } else {
      rive.play()
    }

    setIsPlaying(!isPlaying)
  }

  const eventHandlers = {
    ...((playMethod === 'hover' || playMethod === 'hoverReverse') && {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave
    }),
    ...(playMethod === 'click' && { onClick: handleClick })
  }

  return (
    <div
      className={className}
      ref={playMethod === 'observer' ? containerRef : undefined}
      {...eventHandlers}
    >
      <RiveComponent />
    </div>
  )
}
