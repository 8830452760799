import { FaInfo, FaTriangleExclamation } from 'react-icons/fa6'

import type { HelperTextVariant } from './types'

/**
 * Returns the icon based on the helper text variant.
 *
 * @param variant - The variant of the helper text.
 *
 * @returns The JSX element representing the default icon.
 */
export const getIcon = (variant: HelperTextVariant): JSX.Element => {
  switch (variant) {
    case 'danger':
      return <FaTriangleExclamation size={20} color="#ff1000" />
    case 'alert':
      return <FaTriangleExclamation size={20} color="rgb(255, 217, 0)" />
    case 'info':
      return <FaInfo size={20} color="rgb(0, 122, 255)" />
    default:
      return <FaInfo size={20} color="rgb(0, 122, 255)" />
  }
}
