'use client'

import { ChevronLeft, ChevronRight } from 'lucide-react'
import type { DayPickerProps } from 'react-day-picker'
import { DayPicker } from 'react-day-picker'

import { cn } from '~/UI-Temp/src/utils/cn'

import { buttonVariants } from '../../UI/Button'

/**
 * Props for the Calendar component.
 */
type CalendarProps = Omit<DayPickerProps, 'disabled'> & {
  /** The earliest date that can be selected. Dates before this will be disabled. */
  fromDate?: Date
  /** The latest date that can be selected. Dates after this will be disabled. */
  toDate?: Date
}

/**
 * Calendar component that wraps react-day-picker with custom styling and additional props.
 *
 * @param {CalendarProps} props - Props for the Calendar component.
 * @returns {JSX.Element} The rendered Calendar component.
 *
 * @throws {Error} Throws an error if `fromDate` is after `toDate`.
 */
export function Calendar({
  className = '',
  classNames = {},
  showOutsideDays = true,
  fromDate,
  toDate,
  disabled: disabledProp,
  ...props
}: CalendarProps): JSX.Element {
  // Validate that fromDate is not after toDate
  if (fromDate && toDate && fromDate > toDate) {
    throw new Error('Invalid props: fromDate should not be after toDate.')
  }

  // Combine disabledProp with fromDate and toDate
  const disabled = {
    ...disabledProp,
    ...(fromDate ? { before: fromDate } : {}),
    ...(toDate ? { after: toDate } : {})
  }

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'primary' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'secondary' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
        ),
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-accent text-accent-foreground',
        day_outside: 'text-muted-foreground opacity-50',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames
      }}
      components={{
        IconLeft: () => {
          return <ChevronLeft size={16} aria-hidden="true" />
        },
        IconRight: () => {
          return <ChevronRight size={16} aria-hidden="true" />
        }
      }}
      disabled={disabled}
      {...props}
    />
  )
}

Calendar.displayName = 'Calendar'
