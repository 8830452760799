import type { ButtonHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses =
  'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'

/**
 * Creates an object that generates classnames based on the provided variants.
 * The base classes should be applied to all instances of the component.
 * Different variants (e.g., primary, secondary, etc.) and the classes that should be applied for each variant.
 * @type {function}
 */
export const buttonVariants = cva(baseClasses, {
  variants: {
    variant: {
      default: 'bg-primary text-primary-foreground hover:bg-primary/90',
      primary:
        'bg-[--button-primary] font-bold text-[--button-primary-foreground] hover:bg-[--button-primary-hover] active:bg-[--button-primary-active]',
      inline: 'border-brand-300/10 border-b pb-1 leading-none',
      CTA: 'bg-[--button-cta] font-bold uppercase text-[--button-cta-foreground] hover:bg-[--button-cta-hover] active:bg-[--button-cta-active]',
      destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
      outline:
        'border border-input bg-background text-foreground hover:bg-accent hover:text-accent-foreground',
      secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
      ghost: 'text-foreground hover:bg-accent hover:text-accent-foreground',
      link: 'text-primary underline-offset-4 hover:underline'
    },
    size: {
      default: 'h-10 px-4 py-2',
      sm: 'h-9 rounded-md px-3',
      lg: 'h-11 rounded-md px-8',
      icon: 'size-10'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
})

/**
 * @typedef {Object} ButtonProps - Base Button properties
 * @property {React.ButtonHTMLAttributes<HTMLButtonElement>} React.ButtonHTMLAttributes<HTMLButtonElement> - HTML Button properties
 * @property {VariantProps<typeof buttonVariants>} VariantProps<typeof buttonVariants> - Button variants
 * @property {ElementType} as - Element type
 */

export type ButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'destructive'
  | 'outline'
  | 'ghost'
  | 'link'
  | 'inline'
  | 'CTA'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean
  className?: string
  variant?: ButtonVariant
} & VariantProps<typeof buttonVariants>

/**
 * Button component
 * Generates the className for the component using our `buttonVariants` object, which will add classes for the current variant.
 * @component
 * @param {ButtonProps} props - Button properties
 *
 * @returns {JSX.Element} Button component
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    asChild = false,
    className = '',
    variant = 'default',
    size = 'default',
    ...otherProps
  } = props

  const combinedClasses = cn(buttonVariants({ variant, size }), className)

  const componentProps = {
    className: combinedClasses,
    ref,
    ...otherProps
  }

  if (asChild) {
    return <Slot {...componentProps} />
  }

  return <button {...componentProps} />
})

Button.displayName = 'Button'
