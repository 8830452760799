'use client'

import { Label } from '../Label/Label'
import { Switch } from '../Switch/Switch'

type CookieOptionProps = {
  id: string
  title: string
  description: string
  isChecked: boolean
  onChange: (value: boolean) => void
}

/**
 * A component that renders a single cookie option with a switch.
 *
 * @param {CookieOptionProps} props The properties passed to the component.
 *
 * @returns {JSX.Element} The JSX code for rendering the cookie option.
 */
export const CookieOption = ({
  id,
  title,
  description,
  isChecked,
  onChange
}: CookieOptionProps) => {
  return (
    <div className="flex items-center justify-between space-x-4">
      <Label htmlFor={id} className="flex flex-col space-y-1">
        <span>{title}</span>
        <span className="text-xs font-normal leading-snug text-muted-foreground">
          {description}
        </span>
      </Label>
      <Switch
        id={id}
        checked={isChecked}
        onChange={e => {
          return onChange((e.target as HTMLInputElement).checked)
        }}
        aria-label={title}
      />
    </div>
  )
}
