import type { ElementType, ReactNode } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { Heading } from '../../UI/Heading'
import { cn, missingClass } from '../../utils'

const baseClasses = 'grid-auto-columns w-full gap-4 md:gap-8'

const sectionVariants = cva(baseClasses, {
  variants: {
    display: {
      flex: 'flex',
      grid: 'grid'
    },
    divider: {
      none: 'border-none',
      top: 'border-brand-300/05 border-t',
      bottom: 'border-brand-300/05 border-b',
      both: 'border-brand-300/05 border-y'
    },
    padding: {
      x: 'px-6 md:px-8 lg:px-12',
      y: 'py-6 md:py-8 lg:py-12',
      all: 'p-6 md:p-8 lg:p-12'
    }
  },
  defaultVariants: {
    display: 'grid',
    divider: 'none',
    padding: 'all'
  }
})

export type SectionProps = {
  as?: ElementType
  children?: ReactNode
  className?: string
  heading?: string
} & VariantProps<typeof sectionVariants>

/**
 * A Section component that allows you to easily create a section element with various styles.
 *
 * @param {SectionProps} props - The component props
 * @param {ElementType} [props.as='section'] - The element type to render the text as
 * @param {ReactNode} [props.children] - The children to be rendered
 * @param {string} [props.className] - Additional class names to be added to the component
 * @param {'none'|'top'|'bottom'|'both'} [props.divider='none'] - The divider to be added to the top or bottom of the section
 * @param {'grid'|'flex'} [props.display='grid'] - The display of the section
 * @param {string} [props.heading] - The heading of the section
 * @param {'x'|'y'|'all'} [props.padding='all'] - The padding to be added to the section
 * @returns {JSX.Element} - The Section component
 */
export function Section({
  as: Component = 'section',
  children,
  className,
  heading,
  ...props
}: SectionProps) {
  const combinedClasses = cn(
    sectionVariants(props),
    missingClass(className, '\\mp[xy]?-'),
    className
  )

  return (
    <Component className={combinedClasses}>
      {heading && (
        <Heading
          size="lead"
          className={cn(props.padding === 'y' ? sectionVariants({ padding: 'x' }) : '')}
        >
          {heading}
        </Heading>
      )}
      {children}
    </Component>
  )
}
