'use client'

import { Button } from '../Button'
import { Text } from '../Text'

export type TableAction<T extends object = any> = {
  label: string
  icon: React.ReactNode
  handler: (row: T) => void
}

type TableActionButtonsProps<T extends object = any> = {
  actions: TableAction<T>[]
  row: any
}

export function TableActionButtons({ actions, row }: TableActionButtonsProps) {
  return (
    <td className="flex gap-x-4 py-2">
      {actions.map((action, index) => {
        return (
          <Button
            key={action.label.toString() + index}
            type="button"
            onClick={() => {
              return action.handler(row.original)
            }}
            className="flex items-center justify-center gap-x-2 rounded-lg bg-indigo-100 px-2 py-1 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <Text as="span" className="w-full px-4">
              {action.label}
            </Text>
            <Text as="span" className="pr-2">
              {action.icon}
            </Text>
          </Button>
        )
      })}
    </td>
  )
}
