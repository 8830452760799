import type { ElementType } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = 'grid'

const gridVariants = cva(baseClasses, {
  variants: {
    flow: {
      row: 'grid-flow-row',
      col: 'grid-flow-col'
    },
    gap: {
      default: 'gap-2 gap-y-6 md:gap-4 lg:gap-6',
      blog: 'gap-6'
    },
    layout: {
      default: '', // will be dynamically generated based on items
      auto: 'auto-cols-auto'
    }
  },
  defaultVariants: {
    flow: 'row',
    gap: 'default',
    layout: 'default'
  }
})

type GridProps = {
  as?: ElementType
  className?: string
  items?: number
} & VariantProps<typeof gridVariants>

/**
 * A Grid component that allows you to easily create grid elements with various styles.
 *
 * @param {GridProps} props - The component props
 * @param {ElementType} [props.as='div'] - The element type to render the grid as
 * @param {string} [props.className] - Additional class names to be added to the component
 * @param {'row'|'col'} [props.flow='row'] - The flow of the grid
 * @param {'default'|'blog'} [props.gap='default'] - The gap between the grid items
 * @param {number} [props.items=4] - The number of items to display in the grid
 * @param {'default'|'auto'} [props.layout='default'] - The layout of the grid
 * @returns {JSX.Element} - The Grid component
 */
export function Grid({ as: Component = 'div', className, items = 4, ...otherProps }: GridProps) {
  const layoutClasses = cn(
    'grid-cols-1',
    items === 2 && 'md:grid-cols-2',
    items === 3 && 'sm:grid-cols-3',
    items > 3 && 'md:grid-cols-3',
    items >= 4 && 'lg:grid-cols-4'
  )

  const combinedClasses = cn(gridVariants(otherProps), layoutClasses, className)

  return <Component {...otherProps} className={combinedClasses} />
}
