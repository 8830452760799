/* eslint-disable complexity */

'use client'

import type { InputHTMLAttributes } from 'react'
import { forwardRef, useState } from 'react'

import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { FaEye, FaEyeSlash } from 'react-icons/fa6'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Button } from '../Button/Button'
import { FeedbackText } from '../FeedbackText/FeedbackText'
import type { HelperTextVariant } from '../FeedbackText/types'
import { Label } from '../Label/Label'

/**
 * `Input` is a highly customizable input component that supports various styles and configurations.
 * It offers an extensive API for common use cases such as displaying helper text or error messages,
 * toggling password visibility, and rendering icons inside the input.
 */

/**
 * Defines the possible positions for icons within the input component (left or right).
 */
type IconPosition = 'left' | 'right'

const baseClasses =
  'rounded border p-2 w-full focus:outline-none focus:ring-2 focus:ring-brand-200 transition disabled:opacity-30'

const fileClasses = 'border border-gray-300 p-2 rounded w-full'
const shadcnClasses =
  'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'

const searchClasses =
  'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'

const inputVariants = cva(baseClasses, {
  variants: {
    variant: {
      text: baseClasses,
      file: fileClasses,
      shadcn: shadcnClasses,
      password: baseClasses,
      search: searchClasses
    }
  },
  defaultVariants: {
    variant: 'text'
  }
})

type InputVariantProps = VariantProps<typeof inputVariants>

type InputAdditionalProps = {
  label?: string
  helperText?: string
  helperVariant?: HelperTextVariant
  isRequired?: boolean
  hasError?: boolean
  errorText?: string
  icon?: JSX.Element
  onIconClick?: () => void
  iconPosition?: IconPosition
  containerClassName?: string
}

export type InputProps = InputVariantProps &
  InputAdditionalProps &
  InputHTMLAttributes<HTMLInputElement>

/**
 * The `Input` component props, derived from various utility types and extended with custom attributes.
 * @typedef {Object} InputProps
 * @property {VariantProps<typeof inputVariants> & InputHTMLAttributes<HTMLInputElement>} - Inherits standard input props and adds styling variants.
 * @property {string} [label] - Descriptive label text for the input.
 * @property {string} [helperText] - Text providing additional information or feedback related to the input's value.
 * @property {HelperTextVariant} [helperVariant="info"] - Theme variant for the helper text.
 * @property {boolean} [isRequired] - Indicates whether the input is required.
 * @property {boolean} [hasError] - Indicates whether the input has an error.
 * @property {string} [errorText] - Text to display when the input has an error.
 * @property {JSX.Element} [icon] - React component or HTML tag for an icon to display inside the input.
 * @property {Function} [onIconClick] - Callback for click events on the icon.
 * @property {IconPosition} [iconPosition="left"] - Position of the icon within the input.
 * @property {string} [containerClassName] - Additional class names for the container div.
 */

/**
 * `Input` wraps an HTML input element with additional features like icons, helper text, and custom styles.
 * It's designed to be a drop-in replacement for native inputs with enhanced functionality.
 *
 * @param {InputProps} props - Destructured props object for the `Input` component.
 * @param {ForwardedRef<HTMLInputElement>} ref - Forwarded ref for the underlying input element.
 *
 * @example
 * // Basic usage
 * <Input id="username" label="Username" placeholder="Enter your username" />
 *
 * @example
 * // Input with icon and helper text
 * <Input
 *  id="password"
 * label="Password"
 * placeholder="Enter your password"
 * variant="password"
 * icon={FaLock}
 * helperText="Password must be at least 8 characters long."
 * />
 *
 * @example
 * // Input with error state
 * <Input
 * id="email"
 * label="Email"
 * placeholder="Enter your email"
 * hasError
 * errorText="Please enter a valid email address."
 * />
 *
 * @example
 * // Input with custom icon and helper text variant
 * <Input
 * id="search"
 * label="Search"
 * placeholder="Search for a product"
 * icon={FaSearch}
 * helperText="No results found."
 * helperVariant="danger"
 * containerClassName="mb-4 flex flex-col"
 * />
 *
 * @returns {JSX.Element} A component representing a stylized input field with additional features.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    variant = 'text',
    label,
    hasError = false,
    isRequired = false,
    errorText = '',
    icon: Icon,
    onIconClick,
    iconPosition = 'left',
    helperVariant = 'info',
    helperText,
    className = '',
    containerClassName = '',
    ...otherProps
  } = props

  const [showPassword, setShowPassword] = useState(false)
  const isPasswordVariant = variant === 'password'

  const handleToggleShowPassword = () => {
    return setShowPassword(!showPassword)
  }

  const iconClasses = cn(
    'absolute inset-y-0 flex items-center',
    {
      'left-3': iconPosition === 'left',
      'right-3': iconPosition === 'right'
    },
    'pointer-events-none'
  )

  const inputClassNames = cn(
    inputVariants({ variant }),
    {
      'pl-10': iconPosition === 'left' && Icon,
      'pr-10': iconPosition === 'right' && Icon,
      'focus:border-red-500 focus:ring-red-500 border-red-500': hasError
    },
    className
  )

  return (
    <div className={containerClassName}>
      {label && (
        <Label htmlFor={otherProps.id} className="mb-1 text-sm" isRequired={isRequired}>
          {label}
        </Label>
      )}
      <div className="relative">
        {Icon && (
          <button
            type="button"
            onClick={onIconClick}
            className={cn(iconClasses, 'pointer-events-auto')}
            aria-label="Icon action"
          >
            {Icon}
          </button>
        )}
        <input
          {...otherProps}
          type={isPasswordVariant && !showPassword ? 'password' : 'text'}
          ref={ref}
          className={inputClassNames}
          required={isRequired}
        />
        {isPasswordVariant && (
          <Button
            type="button"
            onClick={handleToggleShowPassword}
            className="absolute inset-y-0 right-3 flex items-center p-0"
            title={showPassword ? 'Hide password' : 'Show password'}
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            {showPassword ? (
              <FaEyeSlash className="text-gray-500" />
            ) : (
              <FaEye className="text-gray-500" />
            )}
            <span className="sr-only">{showPassword ? 'Hide password' : 'Show password'}</span>
          </Button>
        )}
      </div>
      {(helperText || hasError) && (
        <FeedbackText
          helperText={helperText}
          errorText={errorText}
          hasError={hasError}
          helperVariant={helperVariant}
        />
      )}
    </div>
  )
})

Input.displayName = 'Input'
