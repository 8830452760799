'use client'

import { useCallback } from 'react'

import type { ParseResult } from 'papaparse'
import Papa from 'papaparse'

/**
 * Custom hook to parse CSV files.
 *
 * @param {(data: ParseResult<unknown>['data']) => void} onDataLoaded - The callback to call when the CSV data is loaded.
 * @returns {(file: File) => void} - A function that takes a File object and parses it as CSV.
 *
 * @example
 * const parseFile = useParseCSV(data => console.log(data));
 * // To parse a file
 * parseFile(file);
 */
export const useParseCSV = (onDataLoaded: (data: ParseResult<unknown>['data']) => void) => {
  const parseFile = useCallback(
    (file: File) => {
      Papa.parse(file, {
        complete: result => {
          onDataLoaded(result.data)
        },
        header: true
      })
    },
    [onDataLoaded]
  )

  return parseFile
}
