'use client'

import type { MouseEventHandler, ReactNode } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import {
  HiOutlineCheck as CheckCircleIcon,
  HiOutlineExclamationCircle as ExclamationCircleIcon,
  HiOutlineInformationCircle as InformationCircleIcon,
  HiOutlineXCircle as XIcon
} from 'react-icons/hi2'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Heading } from '../../UI/Heading'
import { Text } from '../../UI/Text'

const baseClasses = 'border-l-8 p-4 rounded-md relative flex items-start items-center'

type StatusType = 'info' | 'success' | 'warning' | 'error'

const statusColorMap: Record<StatusType, string> = {
  info: 'border-blue-500 bg-blue-100 text-blue-700',
  success: 'border-green-500 bg-green-100 text-green-700',
  warning: 'border-yellow-500 bg-yellow-100 text-yellow-700',
  error: 'border-red-500 bg-red-100 text-red-700'
}

const buttonColorMap: Record<StatusType, string> = {
  info: 'bg-blue-500 hover:bg-blue-600',
  success: 'bg-green-500 hover:bg-green-600',
  warning: 'bg-yellow-500 hover:bg-yellow-600',
  error: 'bg-red-500 hover:bg-red-600'
}

const fillColorMap: Record<StatusType, string> = {
  info: 'fill-blue-500',
  success: 'fill-green-500',
  warning: 'fill-yellow-500',
  error: 'fill-red-500'
}

const icons: Record<NonNullable<AlertProps['status']>, JSX.Element> = {
  error: <ExclamationCircleIcon className="size-8 fill-red-500 text-red-100" aria-hidden="true" />,
  success: <CheckCircleIcon className="size-8 fill-green-500 text-green-100" aria-hidden="true" />,
  info: <InformationCircleIcon className="size-8 fill-blue-500 text-blue-100" aria-hidden="true" />,
  warning: (
    <ExclamationCircleIcon className="size-8 fill-yellow-500 text-yellow-100" aria-hidden="true" />
  )
}

const stickyAlertVariants = cva(baseClasses, {
  variants: {
    status: statusColorMap
  },
  defaultVariants: {
    status: 'info'
  }
})

type AlertProps = {
  status?: StatusType
  title: ReactNode
  description?: ReactNode
  onClose?: MouseEventHandler<HTMLButtonElement>
}

export type StickyAlertProps = {
  buttonLabel?: string
  onButtonClick?: MouseEventHandler<HTMLButtonElement>
} & VariantProps<typeof stickyAlertVariants> &
  AlertProps

/**
 * A sticky alert component with customizable status, title, description, and optional close button and action button.
 *
 * @param {Object} props - The props for the StickyAlert component.
 * @param {StatusType} [props.status='info'] - The status of the alert. Can be 'info', 'success', 'warning', or 'error'.
 * @param {ReactNode} props.title - The title of the alert.
 * @param {ReactNode} [props.description] - The optional description of the alert.
 * @param {MouseEventHandler<HTMLButtonElement>} [props.onClose] - The optional callback function to handle the close button click event.
 * @param {string} [props.buttonLabel] - The optional label for the action button.
 * @param {MouseEventHandler<HTMLButtonElement>} [props.onButtonClick] - The optional callback function to handle the action button click event.
 *
 * @returns {JSX.Element} The rendered StickyAlert component.
 *
 * @example
 * // Basic usage
 * <StickyAlert
 *   status="success"
 *   title="Success!"
 *   description="Your action was completed successfully."
 * />
 *
 * @example
 * // With close button and action button
 * <StickyAlert
 *   status="warning"
 *   title="Warning!"
 *   description="Please confirm your action."
 *   onClose={handleClose}
 *   buttonLabel="Confirm"
 *   onButtonClick={handleConfirm}
 * />
 */
export const StickyAlert = (props: StickyAlertProps) => {
  const { status = 'info', title, description, onClose, buttonLabel, onButtonClick } = props

  const combinedClasses = cn(stickyAlertVariants({ status }))

  return (
    <div className={combinedClasses}>
      <div className="flex items-center">
        <div>{icons[status]}</div>
        <div className="ml-4">
          <Heading as="h4" className="font-semibold">
            {title}
          </Heading>
          {description && <Text className="mt-1">{description}</Text>}
        </div>
      </div>
      <div className="ml-auto flex items-center space-x-2 pl-3">
        {onClose && (
          <div className="shrink-0">
            <button
              onClick={onClose}
              className={`inline-flex ${buttonColorMap[status]} rounded-full text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
              type="button"
              aria-label="Close alert"
            >
              <Text as="span" className="sr-only">
                Close
              </Text>
              <XIcon className={`size-8 ${fillColorMap[status]}`} aria-hidden="true" />
            </button>
          </div>
        )}
        {buttonLabel && (
          <div className="shrink-0">
            <button
              onClick={onButtonClick}
              className={`inline-flex ${buttonColorMap[status]} text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
              type="button"
            >
              {buttonLabel}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
