'use client'

import { useCallback, useRef } from 'react'

/**
 * Custom hook to debounce function calls.
 * This hook ensures that the function provided only executes after the specified delay
 * since the last invocation. If the function is called again before the delay is over,
 * the previous call is cancelled. It also provides a cancel method to immediately cancel
 * the debounced call.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The debounce delay in milliseconds.
 * @returns {object} - An object containing the debounced function and a cancel method.
 *
 * @example
 * const { debouncedFunction, cancel } = useDebounce(() => {
 *   console.log('Button clicked after delay!');
 * }, 500);
 *
 * return <button onClick={debouncedFunction}>Click me</button>;
 */
export function useDebounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): { debouncedFunction: (...args: Parameters<T>) => void; cancel: () => void } {
  const argsRef = useRef<Parameters<T>>()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const debouncedFunction = useCallback(
    (...args: Parameters<T>) => {
      argsRef.current = args

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        if (argsRef.current) {
          func(...argsRef.current)
        }
      }, delay)
    },
    [func, delay]
  )

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  return { debouncedFunction, cancel }
}
