'use client'

import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Button, Heading, Text } from '..'

const stateViewBaseClasses = 'rounded-md p-4 text-sm'

export const stateViewVariants = cva(stateViewBaseClasses, {
  variants: {
    type: {
      info: 'bg-blue-100 text-blue-800',
      success: 'bg-green-100 text-green-800',
      warning: 'bg-yellow-100 text-yellow-800',
      error: 'bg-red-100 text-red-800'
    }
  },
  defaultVariants: {
    type: 'info'
  }
})

export type StateViewProps = VariantProps<typeof stateViewVariants> & {
  imageSrc?: string
  title?: string
  description?: string
  buttonText?: string
  onButtonClick?: () => void
}

const defaultImageSrc = 'path/to/default-view-image.png' // Replace with your default image path
const defaultTitle = 'Nothing to Show Here'
const defaultDescription = 'No data available at the moment. Please check back later.'

/**
 * State view component displays a friendly message and guidance for various states or scenarios where content needs to be displayed.
 *
 * @param {StateViewProps} props - The props for the component.
 * @param {string} [props.imageSrc] - The source URL of the image to display. Defaults to a default state view image.
 * @param {string} [props.title] - The title of the state view message. Defaults to "Nothing to Show Here".
 * @param {string} [props.description] - The description of the state view message. Defaults to "No data available at the moment. Please check back later."
 * @param {string} [props.buttonText] - The text to display on the button. If not provided, no button will be rendered.
 * @param {function} [props.onButtonClick] - The callback function to be invoked when the button is clicked.
 * @param {string} [props.type] - The type of the state view message. Can be "info", "success", "warning", or "error". Defaults to "info".
 *
 * @returns {React.ReactElement} - A React element representing the State view component.
 *
 * @example
 * // Empty state for a collection of products
 * <StateView
 *   imageSrc="path/to/empty-products-image.png"
 *   title="No Products Found"
 *   description="There are no products available at the moment. Please check back later."
 *   buttonText="Browse Products"
 *   onButtonClick={() => navigateToProductsPage()}
 * />
 *
 * @example
 * // 404 Not Found error
 * <StateView
 *   type="error"
 *   imageSrc="path/to/404-error-image.png"
 *   title="Page Not Found"
 *   description="The page you are looking for could not be found."
 *   buttonText="Go to Home"
 *   onButtonClick={() => navigateToHomePage()}
 * />
 *
 * @example
 * // 500 Internal Server Error
 * <StateView
 *   type="error"
 *   imageSrc="path/to/500-error-image.png"
 *   title="Internal Server Error"
 *   description="Something went wrong on our end. Please try again later."
 *   buttonText="Refresh"
 *   onButtonClick={() => window.location.reload()}
 * />
 *
 * @example
 * // Successful operation
 * <StateView
 *   type="success"
 *   imageSrc="path/to/success-image.png"
 *   title="Operation Successful"
 *   description="Your request has been processed successfully."
 *   buttonText="Go Back"
 *   onButtonClick={() => navigateBack()}
 * />
 *
 * @example
 * // Maintenance mode
 * <StateView
 *   type="warning"
 *   imageSrc="path/to/maintenance-image.png"
 *   title="Maintenance Mode"
 *   description="We are currently undergoing maintenance. Please check back later."
 * />
 */
export const StateView = forwardRef<HTMLDivElement, StateViewProps>(
  (
    {
      type,
      imageSrc = defaultImageSrc,
      title = defaultTitle,
      description = defaultDescription,
      buttonText,
      onButtonClick,
      ...otherProps
    },
    ref
  ) => {
    const stateViewClasses = stateViewVariants({ type })

    return (
      <div
        className={cn(
          stateViewClasses,
          'flex flex-col items-center justify-center text-center p-4'
        )}
        ref={ref}
        {...otherProps}
      >
        {/* TODO: Replace with NextImage */}
        <img src={imageSrc} alt="View" className="mb-4 w-52" />
        <Heading as="h2" className="mb-2 text-xl font-semibold">
          {title}
        </Heading>
        <Text className="mb-4 text-gray-600">{description}</Text>
        {buttonText && (
          <Button
            onClick={onButtonClick}
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            {buttonText}
          </Button>
        )}
      </div>
    )
  }
)

StateView.displayName = 'StateView'
