'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import type { FieldErrors, SubmitHandler, UseFormProps } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import type { ZodSchema, TypeOf as ZodTypeOf } from 'zod'

/**
 * Defines the properties for the useZodForm hook. It extends the UseFormProps
 * from react-hook-form by adding an optional Zod schema for validation.
 *
 * @template TSchema - The Zod schema used for form validation.
 */
type UseZodFormProps<TSchema extends ZodSchema<any>> = Omit<
  UseFormProps<ZodTypeOf<TSchema>>,
  'resolver'
> & {
  schema?: TSchema
}

/**
 * A custom hook that integrates Zod schema validation with react-hook-form.
 * This enables strict type checking and validation based on the provided Zod schema,
 * enhancing form handling with type safety and validation logic.
 *
 * @param {UseZodFormProps<TSchema>} props - The configuration options for the form,
 * including the Zod schema for validation and other settings supported by useForm.
 * @returns An object containing all the react-hook-form methods, extended with Zod validation
 * if a schema is provided.
 * @template TSchema - The Zod schema used for form validation, determining the shape
 * and validation rules of the form data.
 */
export function useZodForm<TSchema extends ZodSchema<any>>(props: UseZodFormProps<TSchema>) {
  const methods = useForm<ZodTypeOf<TSchema>>({
    ...props,
    resolver: props.schema ? zodResolver(props.schema) : undefined
  })

  const {
    handleSubmit,
    formState: { errors }
  } = methods

  type FormData = ZodTypeOf<TSchema>

  /**
   * An enhanced version of the handleSubmit function from react-hook-form that
   * automatically focuses the first field with an error after submission fails.
   * This function wraps around the original handleSubmit to provide this additional behavior.
   *
   * @param {SubmitHandler<FormData>} onValid - A callback function to be executed when
   * the form is valid and submission is successful.
   * @param {SubmitHandler<FormData>} onInvalid - An optional callback function to be executed when
   * the form is invalid.
   * @returns A function that can be used as an event handler for form submission.
   */
  const enhancedHandleSubmit: (
    onValid: SubmitHandler<FormData>,
    onInvalid?: SubmitHandler<FormData>
  ) => (e?: React.BaseSyntheticEvent) => Promise<void> = (onValid, onInvalid) => {
    return handleSubmit(onValid, (errors: FieldErrors<FormData>) => {
      focusFirstError()

      if (onInvalid) {
        onInvalid(errors)
      }
    })
  }

  /**
   * Focuses the first input field that has a validation error. This function
   * is called after form submission if there are any validation errors, improving
   * user experience by directing attention to the first error.
   */
  function focusFirstError() {
    const firstErrorKey = Object.keys(errors)[0]

    if (firstErrorKey) {
      const firstErrorField = document.querySelector(`[name="${firstErrorKey}"]`)
      firstErrorField?.focus()
    }
  }

  return {
    ...methods,
    handleSubmit: enhancedHandleSubmit
  }
}
