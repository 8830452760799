/**
 * Check if a class is missing in a string.
 *
 * @param {string} [string] - The string that needs to be checked
 * @param {string} [prefix] - The prefix of the class that needs to be checked
 * @returns {boolean} - returns true if the class is missing, otherwise false
 */
export function missingClass(string?: string, prefix?: string) {
  if (!string || !prefix) {
    return true
  }

  const regex = new RegExp(`\\b${prefix}\\b`, 'g')

  return string.match(regex) === null
}
