'use client'

import * as React from 'react'

import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import type { VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { toggleVariants } from '../Toggle'

const ToggleGroupContext = React.createContext<VariantProps<typeof toggleVariants>>({
  size: 'default',
  variant: 'default'
})

/**
 * A toggle group component for grouping related toggle buttons.
 *
 * @param {Object} props - The props for the ToggleGroup component.
 * @param {string} [props.className] - Additional CSS classes to apply to the toggle group.
 * @param {VariantProps<typeof toggleVariants>} [props.variant] - The variant of the toggle buttons in the group.
 * @param {VariantProps<typeof toggleVariants>} [props.size] - The size of the toggle buttons in the group.
 * @param {React.ReactNode} props.children - The child elements to be rendered within the toggle group.
 * @param {React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>} props.restProps - Additional props to pass to the underlying ToggleGroupPrimitive.Root component.
 * @param {React.ElementRef<typeof ToggleGroupPrimitive.Root>} ref - The ref to be forwarded to the underlying ToggleGroupPrimitive.Root component.
 *
 * @returns {JSX.Element} The rendered ToggleGroup component.
 */
const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, size, children, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Root
      ref={ref}
      className={cn('flex items-center justify-center gap-1', className)}
      {...props}
    >
      <ToggleGroupContext.Provider value={{ variant, size }}>
        {children}
      </ToggleGroupContext.Provider>
    </ToggleGroupPrimitive.Root>
  )
})

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

/**
 * An item component for a toggle group.
 *
 * @param {Object} props - The props for the ToggleGroupItem component.
 * @param {string} [props.className] - Additional CSS classes to apply to the toggle group item.
 * @param {React.ReactNode} props.children - The child elements to be rendered within the toggle group item.
 * @param {VariantProps<typeof toggleVariants>} [props.variant] - The variant of the toggle group item.
 * @param {VariantProps<typeof toggleVariants>} [props.size] - The size of the toggle group item.
 * @param {React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>} props.restProps - Additional props to pass to the underlying ToggleGroupPrimitive.Item component.
 * @param {React.ElementRef<typeof ToggleGroupPrimitive.Item>} ref - The ref to be forwarded to the underlying ToggleGroupPrimitive.Item component.
 *
 * @returns {JSX.Element} The rendered ToggleGroupItem component.
 */
const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> &
    VariantProps<typeof toggleVariants>
>(({ className, children, variant, size, ...props }, ref) => {
  const context = React.useContext(ToggleGroupContext)

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        toggleVariants({
          variant: context.variant || variant,
          size: context.size || size
        }),
        className
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  )
})

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

export { ToggleGroup, ToggleGroupItem }
