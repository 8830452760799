'use client'

import { useEffect, type ComponentProps, type ReactNode } from 'react'

import type {
  DefaultValues,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormProps
} from 'react-hook-form'
import { FormProvider } from 'react-hook-form'
import type { ZodSchema } from 'zod'

import { cn } from '~/UI-Temp/src/utils/cn'

import { useLocalForage, usePageVisibility } from '../../hooks'

import { useZodForm } from './useZodForm'

type FormProps<T extends FieldValues> = Omit<ComponentProps<'form'>, 'onSubmit'> & {
  schema?: ZodSchema<T>
  onSubmit: SubmitHandler<T>
  onError?: SubmitErrorHandler<T>
  formConfig?: UseFormProps<T>
  children?: ReactNode
}

/**
 * A generic form component that uses react-hook-form with Zod schema validation.
 *
 * @param {Props<T>} props - The props for the form.
 * @param {ZodSchema<T> | undefined} props.schema - The Zod schema to use for validation.
 * @param {SubmitHandler<T>} props.onSubmit - Function to call on form submission.
 * @param {SubmitErrorHandler<T> | undefined} props.onError - Function to call on form submission error.
 * @param {UseFormProps<T> | undefined} props.formConfig - Configuration for react-hook-form.
 * @param {React.ReactNode} props.children - Additional form elements.
 * @param {string} props.persistKey - The key to use with localForage for persisting form data.
 * @param {boolean} props.persist - Whether to persist form data.
 * @returns {React.ReactElement} - A form component.
 * @template T - The type of the form's field values.
 */
export const Form = <T extends FieldValues>({
  schema,
  onSubmit,
  onError,
  formConfig = {},
  children,
  persistKey,
  persist = false,
  ...props
}: FormProps<T> & { persistKey?: string; persist?: boolean }) => {
  const [formData, setFormData] = useLocalForage<T>(persistKey || '', {} as T, persist)

  const form = useZodForm({
    schema,
    defaultValues: formData as unknown as DefaultValues<T>,
    ...formConfig
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    getValues
  } = form

  usePageVisibility(persistKey || '', getValues(), persist)

  // Save form data to localForage when the form data changes
  useEffect(() => {
    if (persist) {
      const currentFormData = watch()
      setFormData(currentFormData)
    }
  }, [watch, setFormData, persist])

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit, onError)} {...props}>
        <fieldset className={cn('space-y-4', isSubmitting && 'opacity-50')} disabled={isSubmitting}>
          {children}
        </fieldset>
      </form>
    </FormProvider>
  )
}
