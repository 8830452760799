'use client'

import * as React from 'react'

import { OTPInput, OTPInputContext } from 'input-otp'
import { Dot } from 'lucide-react'

import { cn } from '~/UI-Temp/src/utils/cn'

/**
 * A component that wraps an OTP (One-Time Password) input field, providing styling and functionality.
 *
 * @param {React.ComponentPropsWithoutRef<typeof OTPInput>} props - Component props including those specific to OTPInput, className for input styling, and containerClassName for container styling.
 * @param {React.Ref<React.ElementRef<typeof OTPInput>>} ref - Ref forwarding to access the underlying input element.
 */
const InputOTP = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  React.ComponentPropsWithoutRef<typeof OTPInput>
>(({ className, containerClassName, ...props }, ref) => {
  return (
    <OTPInput
      ref={ref}
      containerClassName={cn(
        'flex items-center gap-2 has-[:disabled]:opacity-50',
        containerClassName
      )}
      className={cn('disabled:cursor-not-allowed', className)}
      {...props}
    />
  )
})
InputOTP.displayName = 'InputOTP'

/**
 * Groups multiple InputOTP components or related elements for structured layout.
 *
 * @param {React.ComponentPropsWithoutRef<"div">} props - Standard div component props for styling and accessibility.
 * @param {React.Ref<React.ElementRef<"div">>} ref - Ref forwarding for DOM access.
 */
const InputOTPGroup = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={cn('flex items-center', className)} {...props} />
})
InputOTPGroup.displayName = 'InputOTPGroup'

/**
 * Represents an individual slot within an OTP input, showing the character and optionally a fake caret.
 *
 * @param {React.ComponentPropsWithoutRef<"div"> & { index: number }} props - Component props including index for identifying the slot, className for styling.
 * @param {React.Ref<React.ElementRef<"div">>} ref - Ref forwarding for DOM access.
 */
const InputOTPSlot = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & { index: number }
>(({ index, className, ...props }, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext)
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index]

  return (
    <div
      ref={ref}
      className={cn(
        'relative flex h-10 w-10 items-center justify-center border-y border-r border-input text-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md',
        isActive && 'z-10 ring-2 ring-ring ring-offset-background',
        className
      )}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className="animate-caret-blink h-4 w-px bg-foreground duration-1000" />
        </div>
      )}
    </div>
  )
})
InputOTPSlot.displayName = 'InputOTPSlot'

/**
 * A visual separator for use between OTP slots, often for aesthetic purposes to denote separation.
 *
 * @param {React.ComponentPropsWithoutRef<"div">} props - Standard div component props.
 * @param {React.Ref<React.ElementRef<"div">>} ref - Ref forwarding for DOM access.
 */
const InputOTPSeparator = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ ...props }, ref) => {
  return (
    <div ref={ref} role="separator" {...props}>
      <Dot />
    </div>
  )
})
InputOTPSeparator.displayName = 'InputOTPSeparator'

export { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot }
