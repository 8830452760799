import { cn } from '~/UI-Temp/src/utils/cn'

import { Text } from '../../UI/Text'

import { getHelperTextStyle } from './getHelperTextStyle'
import { getIcon } from './getIcon'
import type { HelperTextVariant } from './types'

/**
 * Props for the FeedbackText component.
 *
 * @typedef FeedbackTextProps
 *
 * @property {string} [helperText] - The helper text to display.
 * @property {HelperTextVariant} [helperVariant="info"] - The variant of the helper text.
 * @property {string} [errorText] - The error text to display.
 * @property {boolean} [hasError=false] - Indicates whether there is an error.
 */
type FeedbackTextProps = {
  helperText?: string
  helperVariant?: HelperTextVariant
  errorText?: string
  hasError?: boolean
}

/**
 * A component that displays feedback text with an icon.
 *
 * @param {FeedbackTextProps} props - The props for the FeedbackText component.
 * @returns {JSX.Element} The rendered FeedbackText component.
 *
 * @example
 * // Displaying helper text
 * <FeedbackText helperText="This is a helpful message" />
 *
 * @example
 * // Displaying error text
 * <FeedbackText errorText="This is an error message" hasError />
 *
 * @example
 * // Displaying helper text with a specific variant
 * <FeedbackText helperText="This is an info message" helperVariant="info" />
 */
export const FeedbackText = (props: FeedbackTextProps) => {
  const { helperText, helperVariant = 'info', errorText, hasError = false } = props

  const textStyle = hasError ? 'text-red-500' : getHelperTextStyle(helperVariant)
  const text = hasError ? errorText : helperText
  const icon = hasError ? getIcon('danger') : getIcon(helperVariant)

  return (
    <div className={cn('flex items-center text-sm mt-1', textStyle)}>
      <div className="mr-2">{icon}</div>
      <Text>{text}</Text>
    </div>
  )
}
