'use client'

import type { ComponentProps } from 'react'
import React, { forwardRef } from 'react'

import type {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  SelectComponentsConfig,
  StylesConfig
} from 'react-select'
import ReactSelect from 'react-select'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Label } from '..'
import { FeedbackText } from '../FeedbackText/FeedbackText'
import type { HelperTextVariant } from '../FeedbackText/types'

import { getCustomStyles, getDefaultComponents } from './'

/**
 * Represents an option in the select component.
 * @template T The type of the value property (defaults to string).
 */
export type OptionType<T = string> = {
  value: T
  label: string
  isDisabled?: boolean
}

/**
 * Props for the Select component.
 * @template Option The type of the option (defaults to OptionType).
 * @template IsMulti Whether multiple selections are allowed (defaults to false).
 * @template Group The type of option grouping (defaults to GroupBase<Option>).
 */
export type SelectPropsType<
  Option = OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  /** The label for the select input */
  label?: string
  /** Whether the select input is required */
  isRequired?: boolean
  /** The options to display in the select input */
  options: Option[]
  /** Whether multiple selections are allowed */
  isMulti?: IsMulti
  /** Whether the select input can be cleared */
  isClearable?: boolean
  /** Whether the options can be searched */
  isSearchable?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** Helper text to display below the input */
  helperText?: string
  /** The variant of the helper text */
  helperVariant?: HelperTextVariant
  /** Error text to display when hasError is true */
  errorText?: string
  /** Custom styles for the select input */
  styles?: StylesConfig<Option, IsMulti, Group>
  /** Custom components for the select input */
  components?: SelectComponentsConfig<Option, IsMulti, Group>
  /** Callback function when the selection changes */
  onChange?: (value: OnChangeValue<Option, IsMulti>, action: ActionMeta<Option>) => void
  /** The variant of the custom styles to apply */
  stylesVariant?: 'default' | 'naked' | 'modern' | 'shadcn' | 'minimal'
  /** The variant of the custom components to apply */
  componentsVariant?: 'default' | 'naked' | 'modern' | 'shadcn' | 'minimal'
} & Omit<ComponentProps<typeof ReactSelect>, 'options'>

/**
 * A customizable select component that wraps react-select.
 * @template Option The type of the option (defaults to OptionType).
 * @template IsMulti Whether multiple selections are allowed (defaults to false).
 * @template Group The type of option grouping (defaults to GroupBase<Option>).
 */
export const Select = forwardRef(
  <
    Option = OptionType,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  >(
    props: SelectPropsType<Option, IsMulti, Group>,
    ref: React.ForwardedRef<any>
  ) => {
    const {
      label,
      isRequired = false,
      options,
      isMulti = false as IsMulti,
      isClearable = false,
      isSearchable = false,
      hasError = false,
      helperVariant = 'info',
      helperText,
      errorText = '',
      className = '',
      styles,
      onChange,
      components,
      menuPosition = 'fixed',
      menuPlacement = 'auto',
      menuShouldBlockScroll = true,
      stylesVariant = 'default',
      componentsVariant = 'default',
      ...otherProps
    } = props

    const customStyles = getCustomStyles(stylesVariant, hasError)
    const defaultComponents = getDefaultComponents(componentsVariant)

    const selectClassNames = cn(
      'react-select',
      { 'border-red-500 text-red-500': hasError },
      className
    )

    return (
      <div className="mb-4 flex flex-col">
        {label && (
          <Label 
            htmlFor={otherProps.id} 
            className="mb-1 text-sm"
            isRequired={isRequired}
          >
            {label}
          </Label>
        )}
        <ReactSelect
          {...otherProps}
          options={options}
          className={selectClassNames}
          isMulti={isMulti}
          isClearable={isClearable}
          isSearchable={isSearchable}
          required={isRequired}
          ref={ref}
          onChange={onChange}
          styles={{ ...customStyles, ...styles }}
          components={{ ...defaultComponents, ...components }}
          classNamePrefix="react-select"
          noOptionsMessage={() => {
            return 'No options'
          }}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          menuShouldBlockScroll={menuShouldBlockScroll}
        />
        {(helperText || hasError) && (
          <FeedbackText
            helperText={helperText}
            errorText={errorText}
            hasError={hasError}
            helperVariant={helperVariant}
          />
        )}
      </div>
    )
  }
) as <
  Option = OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectPropsType<Option, IsMulti, Group> & {
    ref?: React.ForwardedRef<any>
  }
) => React.ReactElement

Select.displayName = 'SelectInput'
