import type { TdHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = 'align-middle [&:has([role=checkbox])]:pr-0'

const tableCellVariants = cva(baseClasses, {
  variants: {
    variant: {
      default: 'p-4',
      newYork: 'p-2 [&>[role=checkbox]]:translate-y-[2px]'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

export type TableCellProps = VariantProps<typeof tableCellVariants> &
  TdHTMLAttributes<HTMLTableCellElement>

export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ className, ...props }, ref) => {
    const combinedClasses = cn(tableCellVariants(props), className)

    return <td ref={ref} className={combinedClasses} {...props} />
  }
)

TableCell.displayName = 'TableCell'
