import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'

import * as SeparatorPrimitive from '@radix-ui/react-separator'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

type VariantType = 'default' | 'secondary' | 'zinc'

type ThicknessType = 'thin' | 'thick'

type OrientationType = 'horizontal' | 'vertical'

const baseClasses = 'shrink-0'

export const separatorVariants = cva(baseClasses, {
  variants: {
    variant: {
      default: 'w-full bg-border',
      secondary: 'w-full bg-secondary',
      zinc: 'mx-auto w-10 rounded-md bg-zinc-300 dark:bg-zinc-700'
    },
    thickness: {
      thin: 'h-[1px]',
      thick: 'h-[2px]'
    },
    orientation: {
      horizontal: 'w-full',
      vertical: 'h-full'
    }
  },
  defaultVariants: {
    variant: 'default',
    thickness: 'thin',
    orientation: 'horizontal'
  }
})

export type SeparatorProps = {
  variant?: VariantType
  thickness?: ThicknessType
  orientation?: OrientationType
} & VariantProps<typeof separatorVariants> &
  ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>

/**
 * A customizable separator component.
 *
 * @param {Object} props - The props for the Separator component.
 * @param {VariantType} [props.variant='default'] - The variant of the separator.
 * @param {ThicknessType} [props.thickness='thin'] - The thickness of the separator.
 * @param {OrientationType} [props.orientation='horizontal'] - The orientation of the separator.
 * @param {string} [props.className] - Additional CSS classes to apply to the separator.
 * @param {boolean} [props.decorative=true] - Whether the separator is decorative or not.
 * @param {ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>} [props.otherProps] - Additional props to pass to the underlying SeparatorPrimitive.Root component.
 * @param {ElementRef<typeof SeparatorPrimitive.Root>} ref - The ref to be forwarded to the underlying SeparatorPrimitive.Root component.
 *
 * @returns {JSX.Element} The rendered Separator component.
 *
 * @example
 * // Basic usage
 * <Separator />
 *
 * @example
 * // With custom variant and thickness
 * <Separator variant="secondary" thickness="thick" />
 *
 * @example
 * // With vertical orientation and additional CSS classes
 * <Separator orientation="vertical" className="mx-4" />
 */
export const Separator = forwardRef<ElementRef<typeof SeparatorPrimitive.Root>, SeparatorProps>(
  (props, ref) => {
    const {
      variant = 'default',
      thickness = 'thin',
      orientation = 'horizontal',
      className,
      decorative = true,
      ...otherProps
    } = props

    const combinedClasses = cn(separatorVariants({ variant, thickness, orientation }), className)

    return (
      <SeparatorPrimitive.Root
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={combinedClasses}
        {...otherProps}
      />
    )
  }
)

Separator.displayName = SeparatorPrimitive.Root.displayName
