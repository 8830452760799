'use client'

import type { UrlObject } from 'url'

import type { ReactNode } from 'react'
import { useMemo } from 'react'

import { usePathname } from 'next/navigation'

export type Route = {
  to: UrlObject
  title: string
  titleWithSiteName: string
  description: string
  icon?: ReactNode
  isDisabled?: boolean
  children?: Route[]
}

/**
 * Custom hook for generating breadcrumb data based on the current URL path.
 *
 * @param routes - An object containing the routes of the application.
 * @returns An array of breadcrumb objects, each containing:
 * - `pathname`: the URL path of the breadcrumb.
 * - `breadcrumb`: the display name for the breadcrumb.
 * - `isLast`: boolean indicating if this is the last breadcrumb in the array.
 */
export const useBreadcrumbs = (routes: Record<string, Route>) => {
  const pathname = usePathname()

  const breadcrumbs = useMemo(() => {
    const pathSegments = pathname.split('/').filter(Boolean)
    let pathAccumulator = ''

    // Initialize breadcrumbs array with the root if it exists in routes
    const initialBreadcrumbs = routes['/']
      ? [
          {
            pathname: '/',
            breadcrumb: routes['/'].title,
            isLast: false
          }
        ]
      : []

    const pathBreadcrumbs = pathSegments.map((segment, index) => {
      pathAccumulator += `/${segment}`
      const route = routes[pathAccumulator]

      return {
        pathname: pathAccumulator,
        breadcrumb: route ? route.title : segment,
        isLast: index === pathSegments.length - 1
      }
    })

    // Combine initial breadcrumbs with those generated from path segments
    return [...initialBreadcrumbs, ...pathBreadcrumbs]
  }, [pathname, routes])

  // Ensure the last breadcrumb is marked as last
  if (breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].isLast = true

    if (breadcrumbs.length > 1) {
      breadcrumbs[0].isLast = false // Ensure the root is not marked as last if there are more breadcrumbs
    }
  }

  return breadcrumbs
}
