'use client'

import { useCallback, useState } from 'react'

/**
 * Custom hook to copy text to the clipboard.
 *
 * @param {number} [timeout=2000] - The duration in milliseconds to keep the `isCopied` state true after copying text.
 * @returns {object} - An object with `isCopied` boolean state and `handleCopy` function.
 *
 * @example
 * const { isCopied, handleCopy } = useCopy();
 * // To copy text
 * handleCopy('Text to copy');
 * // `isCopied` will be true for 2 seconds after copying text
 */
export const useCopy = (timeout = 2000) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = useCallback(
    async (text: string) => {
      if (typeof window === 'undefined' || !navigator.clipboard.writeText) {
        console.warn('Clipboard API not available.')

        return
      }

      try {
        await navigator.clipboard.writeText(text)
        setIsCopied(true)

        setTimeout(() => {
          setIsCopied(false)
        }, timeout)
      } catch (err) {
        console.error('useCopy: Failed to copy text: ', err)
        setIsCopied(false)
      }
    },
    [timeout]
  )

  return { isCopied, handleCopy }
}
