'use client'

import * as React from 'react'

import { Drawer as DrawerPrimitive } from 'vaul'

import { cn } from '~/UI-Temp/src/utils/cn'

/**
 * Wrapper component for the Drawer UI element.
 * @param {React.ComponentProps<typeof DrawerPrimitive.Root>} props - Props for Drawer component, including a flag for background scaling.
 * @returns {React.ReactElement} A React element representing the Drawer component.
 */
const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => {
  return <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
}
Drawer.displayName = 'Drawer'

// Drawer trigger component for opening the Drawer.
const DrawerTrigger = DrawerPrimitive.Trigger

// Drawer portal component for rendering Drawer content outside the DOM hierarchy.
const DrawerPortal = DrawerPrimitive.Portal

// Drawer close component for closing the Drawer.
const DrawerClose = DrawerPrimitive.Close

/**
 * Overlay component for the Drawer, rendering a backdrop.
 * @param {React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>} props - Props for DrawerOverlay component.
 * @param {React.Ref<React.ElementRef<typeof DrawerPrimitive.Overlay>>} ref - Ref forwarding for the Drawer overlay.
 * @returns {React.ReactElement} A React element representing the overlay of the Drawer.
 */
const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => {
  return (
    <DrawerPrimitive.Overlay
      ref={ref}
      className={cn('fixed inset-0 z-50 bg-black/80', className)}
      {...props}
    />
  )
})
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName

/**
 * Content component for the Drawer, defining the main area of the Drawer.
 * @param {React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>} props - Props for DrawerContent component, including children elements.
 * @param {React.Ref<React.ElementRef<typeof DrawerPrimitive.Content>>} ref - Ref forwarding for the Drawer content.
 * @returns {React.ReactElement} A React element representing the content of the Drawer.
 */
const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  return (
    <DrawerPortal>
      <DrawerOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          'fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border bg-background',
          className
        )}
        {...props}
      >
        <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  )
})
DrawerContent.displayName = 'DrawerContent'

/**
 * Header component for the Drawer, typically used for title or navigation.
 * @param {React.HTMLAttributes<HTMLDivElement>} props - Props for DrawerHeader component.
 * @returns {React.ReactElement} A React element representing the header of the Drawer.
 */
const DrawerHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('grid gap-1.5 p-4 text-center sm:text-left', className)} {...props} />
}
DrawerHeader.displayName = 'DrawerHeader'

/**
 * Footer component for the Drawer, typically used for actions or additional information.
 * @param {React.HTMLAttributes<HTMLDivElement>} props - Props for DrawerFooter component.
 * @returns {React.ReactElement} A React element representing the footer of the Drawer.
 */
const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('mt-auto flex flex-col gap-2 p-4', className)} {...props} />
}
DrawerFooter.displayName = 'DrawerFooter'

/**
 * Title component for the Drawer, defining the title text.
 * @param {React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>} props - Props for DrawerTitle component.
 * @param {React.Ref<React.ElementRef<typeof DrawerPrimitive.Title>>} ref - Ref forwarding for the Drawer title.
 * @returns {React.ReactElement} A React element representing the title of the Drawer.
 */
const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => {
  return (
    <DrawerPrimitive.Title
      ref={ref}
      className={cn('text-lg font-semibold leading-none tracking-tight', className)}
      {...props}
    />
  )
})
DrawerTitle.displayName = DrawerPrimitive.Title.displayName

/**
 * Description component for the Drawer, typically used for supplementary information.
 * @param {React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>} props - Props for DrawerDescription component.
 * @param {React.Ref<React.ElementRef<typeof DrawerPrimitive.Description>>} ref - Ref forwarding for the Drawer description.
 * @returns {React.ReactElement} A React element representing the descriptive content of the Drawer.
 */
const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => {
  return (
    <DrawerPrimitive.Description
      ref={ref}
      className={cn('text-sm text-muted-foreground', className)}
      {...props}
    />
  )
})
DrawerDescription.displayName = DrawerPrimitive.Description.displayName

export {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger
}
