import type { ForwardedRef, TextareaHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Text } from '../../UI/Text'

const baseClasses = 'w-full rounded-md p-2'

const textareaVariants = cva(baseClasses, {
  variants: {
    state: {
      default: 'border-gray-300 focus:border-blue-500 focus:ring-blue-500',
      error: 'border-red-500'
    },
    variant: {
      default:
        'min-h-[80px] bg-background px-3 py-2 focus-visible:ring-2 focus-visible:ring-offset-2',
      newYork: 'min-h-[60px] bg-transparent shadow-sm focus-visible:ring-1'
    }
  },
  defaultVariants: {
    state: 'default',
    variant: 'default'
  }
})

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string
} & VariantProps<typeof textareaVariants>

/**
 * A Textarea component that allows you to easily create textarea elements with various styles.
 *
 * @param {TextareaProps} props - The component props
 * @param {string} [props.className] - Additional class names to be added to the component
 * @param {string} [props.error] - Error message to display below the textarea
 * @param {'default'|'error'} [props.state='default'] - The textarea state (default or error)
 * @returns {JSX.Element} - The Textarea component
 */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { className, error, ...otherProps } = props
    const stateVariant = error ? 'error' : 'default'
    const combinedClasses = cn(
      textareaVariants({ state: stateVariant, variant: props.variant }),
      className
    )

    return (
      <>
        <textarea {...otherProps} ref={ref} className={combinedClasses} />
        {error && <Text className="mt-1 text-sm text-red-500">{error}</Text>}
      </>
    )
  }
)

Textarea.displayName = 'Textarea'
