'use client'

import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

export const TooltipProvider = TooltipPrimitive.Provider
export const Tooltip = TooltipPrimitive.Root
export const TooltipTrigger = TooltipPrimitive.Trigger

type SizeType = 'small' | 'medium' | 'large'

type ThemeType = 'light' | 'dark'

const baseClasses =
  'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'

export const tooltipContentVariants = cva(baseClasses, {
  variants: {
    size: {
      small: 'px-2 py-1',
      medium: 'px-3 py-1.5',
      large: 'px-4 py-2'
    },
    theme: {
      light: 'bg-white text-black',
      dark: 'bg-black text-white'
    }
  },
  defaultVariants: {
    size: 'medium',
    theme: 'light'
  }
})

export type TooltipContentProps = {
  size?: SizeType
  theme?: ThemeType
} & VariantProps<typeof tooltipContentVariants> &
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>

/**
 * A customizable tooltip content component.
 *
 * @param {Object} props - The props for the TooltipContent component.
 * @param {SizeType} [props.size='medium'] - The size of the tooltip content. Can be 'small', 'medium', or 'large'.
 * @param {ThemeType} [props.theme='light'] - The theme of the tooltip content. Can be 'light' or 'dark'.
 * @param {string} [props.className] - Additional CSS classes to apply to the tooltip content.
 * @param {number} [props.sideOffset=4] - The offset distance from the trigger element.
 * @param {ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>} [props.otherProps] - Additional props to pass to the underlying TooltipPrimitive.Content component.
 * @param {ElementRef<typeof TooltipPrimitive.Content>} ref - The ref to be forwarded to the underlying TooltipPrimitive.Content component.
 *
 * @returns {JSX.Element} The rendered TooltipContent component.
 *
 * @example
 * // Basic usage
 * <Tooltip>
 *   <TooltipTrigger>Hover me</TooltipTrigger>
 *   <TooltipContent>This is a tooltip</TooltipContent>
 * </Tooltip>
 *
 * @example
 * // With custom size and theme
 * <Tooltip>
 *   <TooltipTrigger>Hover me</TooltipTrigger>
 *   <TooltipContent size="large" theme="dark">
 *     This is a large dark tooltip
 *   </TooltipContent>
 * </Tooltip>
 */
export const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>((props, ref) => {
  const { size, theme, className, sideOffset = 4, ...otherProps } = props

  const combinedClasses = cn(tooltipContentVariants({ size, theme }), className)

  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={combinedClasses}
      {...otherProps}
    />
  )
})

TooltipContent.displayName = TooltipPrimitive.Content.displayName
