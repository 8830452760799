'use client'

import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'
import { FaAsterisk } from 'react-icons/fa'

import { cn } from '~/UI-Temp/src/utils/cn'

import { useFormField } from './useFormField'

/**
 * Props for the FormLabel component.
 *
 * @typedef {Object} FormLabelProps
 * @property {boolean} [isDestructive=false] - Whether to apply destructive styling to the label.
 * @property {boolean} [isRequired=false] - Whether to apply required styling to the label.
 * @property {string} [className] - Additional CSS classes to apply to the label.
 * @property {ComponentPropsWithoutRef<typeof LabelPrimitive.Root>} [props] - Additional props to pass to the underlying LabelPrimitive.Root component.
 */
type FormLabelProps = {
  isDestructive?: boolean
  isRequired?: boolean
} & ComponentPropsWithoutRef<typeof LabelPrimitive.Root>

/**
 * A form label component with optional destructive styling.
 *
 * @param {FormLabelProps} props - The props for the FormLabel component.
 * @param {React.Ref<ElementRef<typeof LabelPrimitive.Root>>} ref - The ref to be forwarded to the underlying LabelPrimitive.Root component.
 *
 * @returns {JSX.Element} The rendered FormLabel component.
 *
 * @example
 * // Basic usage
 * <FormLabel>Label text</FormLabel>
 *
 * @example
 * // With destructive styling
 * <FormLabel isDestructive>Label text</FormLabel>
 */
export const FormLabel = forwardRef<ElementRef<typeof LabelPrimitive.Root>, FormLabelProps>(
  ({ className, isDestructive = false, isRequired = false, children, ...props }, ref) => {
    const { error, formItemId } = useFormField()

    return (
      <LabelPrimitive.Label
        ref={ref}
        className={cn(
          'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
          error && isDestructive && 'text-destructive',
          className
        )}
        htmlFor={formItemId}
        {...props}
      >
        {children}
        {isRequired && (
          <span className="ml-1 inline-block text-[0.6em] text-destructive" aria-hidden="true">
            <FaAsterisk />
          </span>
        )}
      </LabelPrimitive.Label>
    )
  }
)

FormLabel.displayName = 'FormLabel'
