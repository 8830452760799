import type { UrlObject } from 'url'

import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import NextLink from 'next/link'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses =
  'inline-block rounded-lg font-medium text-center transition duration-200 border border-transparent'

const linkVariants = cva(baseClasses, {
  variants: {
    variant: {
      primary: 'bg-brand-100 text-white hover:bg-gray-800 focus:bg-gray-800 active:bg-gray-800',
      secondary:
        'bg-contrast text-brand-100 hover:bg-brand-100/10 focus:bg-brand-100/10 active:bg-brand-100/10',
      inline: 'border-b border-primary/10 pb-1 leading-none',
      transparent: ''
    },
    width: {
      auto: 'w-auto',
      full: 'w-full'
    }
  },
  defaultVariants: {
    variant: 'transparent',
    width: 'auto'
  }
})

type MainLinkProps = {
  className?: string
  href: string | (string & UrlObject)
  isExternal?: boolean
  [key: string]: any
} & VariantProps<typeof linkVariants>

export const Link = forwardRef<HTMLAnchorElement, MainLinkProps>(
  (props, ref: ForwardedRef<HTMLAnchorElement>) => {
    const {
      className = '',
      variant = 'transparent',
      width = 'auto',
      href,
      isExternal = false,
      ...restProps
    } = props

    const Component = isExternal ? 'a' : NextLink

    const combinedClasses = cn(linkVariants({ variant, width }), className)

    return <Component ref={ref} className={combinedClasses} href={href} {...restProps} />
  }
)

Link.displayName = 'Link'
