import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = ''

const cardVariants = cva(baseClasses, {
  variants: {
    variant: {
      default: 'rounded-lg border bg-card text-card-foreground shadow-sm',
      newYork: 'rounded-xl border bg-card text-card-foreground shadow',
      simple: 'rounded-lg bg-card text-card-foreground shadow-sm'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

export type CardProps = HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ variant = 'default', className, ...props }, ref) => {
    const combinedClasses = cn(cardVariants({ variant }), className)

    return <div ref={ref} className={combinedClasses} {...props} />
  }
)

Card.displayName = 'Card'
