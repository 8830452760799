'use client'

import { useState } from 'react'

type Filters = Record<string, string | number | boolean | null>

/**
 * Custom hook to manage a set of filters.
 *
 * @param {Filters} initialFilters - The initial state of the filters.
 * @returns {object} - An object with the current filters, a function to change a filter, and a function to reset all filters.
 *
 * @example
 * const { filters, handleFilterChange, resetFilters } = useFilters({ name: '', age: null });
 * // To change a filter
 * handleFilterChange('name', 'John');
 * // To reset all filters
 * resetFilters();
 */
export const useFilters = (initialFilters: Filters) => {
  const [filters, setFilters] = useState<Filters>(initialFilters)

  const handleFilterChange = (
    filterName: keyof Filters,
    filterValue: string | number | boolean | null
  ) => {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [filterName]: filterValue
      }
    })
  }

  const resetFilters = () => {
    setFilters({ ...initialFilters })
  }

  return {
    filters,
    handleFilterChange,
    resetFilters
  }
}
