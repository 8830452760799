'use client'

import { useCallback, useState } from 'react'

import { FaTrashCan } from 'react-icons/fa6'

import { cn } from '~/UI-Temp/src/utils/cn'

import type { FileWithPreview } from './useUpload'

type FileDisplayProps = {
  file: FileWithPreview
  removeFile: (file: FileWithPreview) => void
}

/**
 * Renders the file preview based on the file type.
 *
 * @param {FileWithPreview} file - The file to display.
 *
 * @returns {JSX.Element} The file preview element.
 */
const renderFilePreview = (file: FileWithPreview): JSX.Element => {
  const imagePreviewClass =
    'transition-transform duration-300 ease-in-out object-cover w-full h-full'

  const fileType = file.type.split('/')[0]
  const boxClass = 'border border-gray-300 p-2 rounded aspect-w-1 aspect-h-1'

  switch (fileType) {
    case 'image':
      return (
        <div className={boxClass}>
          <img src={file.preview} alt={`Preview of ${file.name}`} className={imagePreviewClass} />
        </div>
      )
    case 'video':
      return (
        <div className={boxClass}>
          <video src={file.preview} controls className={imagePreviewClass}>
            <track kind="captions" />
          </video>
        </div>
      )
    case 'audio':
      return (
        <div className={boxClass}>
          <audio src={file.preview} controls className={imagePreviewClass}>
            <track kind="captions" />
          </audio>
        </div>
      )
    default:
      return (
        <div className={boxClass}>
          <p className="break-words">{file.name}</p>
        </div>
      )
  }
}

/**
 * Displays a file with a remove button and a preview based on the file type.
 * The component shows a preview of the file, its name, and a remove button that appears on hover.
 *
 * @param {FileDisplayProps} props - The component props.
 * @param {FileWithPreview} props.file - The file to display.
 * @param {(file: FileWithPreview) => void} props.removeFile - Callback function called when the remove button is clicked.
 *
 * @returns {JSX.Element} The FileDisplay component.
 *
 * @example
 * const file: FileWithPreview = {
 *   id: "1",
 *   name: "example.jpg",
 *   type: "image/jpeg",
 *   preview: "blob:http://localhost:3000/1234-5678-9101",
 *   // ... other File properties
 * };
 *
 * const handleRemoveFile = (fileToRemove: FileWithPreview) => {
 *   console.log("Removing file:", fileToRemove);
 *   // Logic to remove the file
 * };
 *
 * return (
 *   <FileDisplay file={file} removeFile={handleRemoveFile} />
 * );
 */
export const FileDisplay = ({ file, removeFile }: FileDisplayProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)

  console.log('FileDisplay_fileeeeeeeeeeeeeeeee', file)

  const imageContainerClass = cn(
    'relative',
    'file-display-container',
    'aspect-w-1 aspect-h-1',
    file.type.split('/')[0] === 'image' ? 'before:aspect-1' : ''
  )

  const trashIconClass = cn(
    'absolute top-0 right-2 text-white cursor-pointer z-10 transition-opacity duration-300',
    isHovered ? 'opacity-100' : 'opacity-0'
  )

  const handleRemove = useCallback(() => {
    removeFile(file)
  }, [file, removeFile])

  return (
    <div>
      <div
        className={imageContainerClass}
        onMouseEnter={() => {
          return setIsHovered(true)
        }}
        onMouseLeave={() => {
          return setIsHovered(false)
        }}
      >
        {renderFilePreview(file)}
        <button
          type="button"
          onClick={handleRemove}
          className={trashIconClass}
          title="Remove file"
          aria-label={`Remove ${file.name}`}
        >
          <FaTrashCan size="1.5em" className="absolute right-2 top-2" />
        </button>
      </div>
      <div className="mt-2 truncate text-sm text-gray-600">{file.name}</div>
    </div>
  )
}
