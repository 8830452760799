import { cva, type VariantProps } from 'class-variance-authority'
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillInfoCircle,
  AiFillWarning
} from 'react-icons/ai'
import { BiError } from 'react-icons/bi'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Button } from '../Button'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { Link } from '../Link'

const baseClasses = 'px-6 py-4 rounded-xl bg-white dark:bg-gray-800 shadow-lg border border-gray-100 dark:border-gray-700 relative flex items-start gap-4'

const statusClasses: Record<string, string> = {
  info: 'border-l-[6px] border-l-blue-500',
  success: 'border-l-[6px] border-l-green-500',
  warning: 'border-l-[6px] border-l-yellow-500',
  error: 'border-l-[6px] border-l-red-500'
}

const alertVariants = cva(baseClasses, {
  variants: {
    status: statusClasses
  },
  defaultVariants: {
    status: 'info'
  }
})

export type AlertProps = {
  title: string
  description?: string
  status?: 'info' | 'success' | 'warning' | 'error'
  onClose?: () => void
  href?: string
  linkText?: string
} & VariantProps<typeof alertVariants>

export const Alert = (props: AlertProps) => {
  const { title, description, status = 'info', onClose, href, linkText } = props

  let Icon

  switch (status) {
    case 'info':
      Icon = <AiFillInfoCircle className="size-5 text-blue-500" />
      break
    case 'success':
      Icon = <AiFillCheckCircle className="size-5 text-green-500" />
      break
    case 'warning':
      Icon = <AiFillWarning className="size-5 text-yellow-500" />
      break
    case 'error':
      Icon = <BiError className="size-5 text-red-500" />
      break
    default:
      break
  }

  const combinedClasses = cn(alertVariants({ status }))

  return (
    <div className={combinedClasses}>
      <div className="flex-shrink-0 pt-1">{Icon}</div>
      <div className="flex-1 min-w-0">
        <div className="flex items-center justify-between gap-4">
          <div>
            <Heading className="text-base font-medium text-gray-900 dark:text-gray-100">
              {title}
            </Heading>
            {description && (
              <Text className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {description}
              </Text>
            )}
          </div>
          {href && linkText && (
            <Button 
              asChild
              variant="outline"
              size="sm"
              className="shrink-0 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <Link href={href}>
                {linkText}
              </Link>
            </Button>
          )}
        </div>
      </div>
      {onClose && (
        <Button 
          onClick={onClose} 
          variant="ghost"
          size="sm"
          className="absolute right-2 top-2 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400" 
        >
          <AiFillCloseCircle className="size-5" />
        </Button>
      )}
    </div>
  )
}
