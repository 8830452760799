import { Cross2Icon } from '@radix-ui/react-icons'
import type { Table } from '@tanstack/react-table'

import type { OptionType } from '../..'
import { Button } from '../../Button'
import { Input } from '../../Input'

import { DataTableFacetedFilter } from './DataTableFacetedFilter'
import { DataTableViewOptions } from './DataTableViewOptions'

type DataTableToolbarProps<TData> = {
  table: Table<TData>
  filterOptions: Record<string, { title: string; options: OptionType[] }>
}

export function DataTableToolbar<TData>({ table, filterOptions }: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Filter tasks..."
          value={(table.getColumn('title')?.getFilterValue() as string) ?? ''}
          onChange={event => {
            return table.getColumn('title')?.setFilterValue(event.target.value)
          }}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {Object.keys(filterOptions).map(key => {
          const column = table.getColumn(key)

          if (column) {
            return (
              <DataTableFacetedFilter
                key={key}
                column={column}
                title={filterOptions[key].title}
                options={filterOptions[key].options}
              />
            )
          }

          return null
        })}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => {
              return table.resetColumnFilters()
            }}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 size-4" />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  )
}
