import type { ThHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses =
  'text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0'

const tableHeadVariants = cva(baseClasses, {
  variants: {
    variant: {
      default: 'h-12 px-4',
      newYork: 'h-10 px-2 [&>[role=checkbox]]:translate-y-[2px]'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

export type TableHeadProps = VariantProps<typeof tableHeadVariants> &
  ThHTMLAttributes<HTMLTableCellElement>

export const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ className, ...props }, ref) => {
    const combinedClasses = cn(tableHeadVariants(props), className)

    return <th ref={ref} className={combinedClasses} {...props} />
  }
)

TableHead.displayName = 'TableHead'
