'use client'

import { useCallback, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

export type FileWithPreview = File & {
  id: string
  preview: string
}

export type UseUploadOptions = {
  allowedFileTypes?: string[]
  maxFileSizeMB?: number
  onFileSelect?: (files: FileWithPreview[]) => void
}

const useUniqueId = () => {
  return useCallback(() => {
    return uuidv4()
  }, [])
}

const ERROR_MESSAGES = {
  fileTooLarge: 'File too large',
  invalidFileType: 'Invalid file type'
} as const

type UseUploadReturnType = {
  selectedFiles: FileWithPreview[]
  error: string | null
  warning: string | null
  handleFiles: (files: FileList) => void
  clearFiles: () => void
  removeFile: (fileToRemove: FileWithPreview) => void
}

/**
 * Custom hook to handle the upload of files with validation for file type and size.
 *
 * @param {UseUploadOptions} [options={}] - Configuration options for file upload.
 * @param {string[]} [options.allowedFileTypes] - Array of allowed file types.
 * @param {number} [options.maxFileSizeMB] - Maximum file size in megabytes.
 * @param {function} [options.onFileSelect] - Callback function to handle selected files.
 *
 * @returns {UseUploadReturnType} - Object containing upload state and utility functions.
 *
 * @example
 * const { selectedFiles, error, handleFiles, clearFiles, removeFile } = useUpload({
 *   allowedFileTypes: ['.jpg', '.png'],
 *   maxFileSizeMB: 5,
 *   onFileSelect: (files) => {
 *     console.log('Selected files:', files);
 *   },
 * });
 */
export const useUpload = (options: UseUploadOptions = {}): UseUploadReturnType => {
  console.log('useUpload_options', options)
  const { allowedFileTypes, maxFileSizeMB, onFileSelect } = options
  const maxFileSizeBytes = maxFileSizeMB ? maxFileSizeMB * 1024 * 1024 : undefined

  const [selectedFiles, setSelectedFiles] = useState<FileWithPreview[]>([])
  const [hasError, setHasError] = useState<string | null>(null)
  const [hasWarning, setHasWarning] = useState<string | null>(null)

  const generateUniqueId = useUniqueId()

  const isValidFileType = useCallback(
    (file: File): boolean => {
      if (!allowedFileTypes) {
        return true
      }

      return allowedFileTypes.some(type => {
        return file.type.startsWith(type)
      })
    },
    [allowedFileTypes]
  )

  const isValidFileSize = useCallback(
    (file: File): boolean => {
      return maxFileSizeBytes ? file.size <= maxFileSizeBytes : true
    },
    [maxFileSizeBytes]
  )

  const getFileError = useCallback(
    (file: File): string | null => {
      if (!isValidFileType(file)) {
        return ERROR_MESSAGES.invalidFileType
      }

      if (!isValidFileSize(file)) {
        return ERROR_MESSAGES.fileTooLarge
      }

      return null
    },
    [isValidFileType, isValidFileSize]
  )

  /**
   * Transforms a file into a FileWithPreview object.
   *
   * @param {File} file - The file to transform.
   *
   * @returns {FileWithPreview} The transformed file.
   */
  const transformFile = useCallback(
    (file: File): FileWithPreview => {
      return Object.assign(file, {
        id: generateUniqueId(),
        preview: URL.createObjectURL(file)
      })
    },
    [generateUniqueId]
  )

  /**
   * Handles the selection of files, performing validation and updating the state.
   *
   * @param {FileList} files - List of files to validate and add to the state.
   *
   * @returns {void} - No return value.
   */
  const handleFiles = useCallback(
    (files: FileList) => {
      console.log('handleFiles called with:', files)

      const filesArray = Array.from(files)
      const fileErrors = filesArray.map(getFileError)
      const newValidFiles = filesArray.filter((_, index) => {
        return fileErrors[index] === null
      })

      if (
        fileErrors.some(error => {
          return error !== null
        })
      ) {
        const errorMessages = fileErrors.filter(error => {
          return error !== null
        })
        setHasError(`Some files were invalid: ${errorMessages.join(', ')}`)
      } else {
        setHasError(null)
      }

      const mappedFiles: FileWithPreview[] = newValidFiles.map(transformFile)

      const duplicates: FileWithPreview[] = []
      const newFiles: FileWithPreview[] = []

      mappedFiles.forEach(newFile => {
        const isDuplicate = selectedFiles.some(existingFile => {
          return existingFile.name === newFile.name && existingFile.size === newFile.size
        })

        if (isDuplicate) {
          duplicates.push(newFile)
        } else {
          newFiles.push(newFile)
        }
      })

      if (duplicates.length > 0) {
        setHasWarning(
          `Duplicate file(s) detected: ${duplicates
            .map(f => {
              return f.name
            })
            .join(', ')}`
        )
      } else {
        setHasWarning(null)
      }

      // Always add new files, even if there are duplicates
      setSelectedFiles(currentFiles => {
        return [...currentFiles, ...newFiles]
      })

      if (onFileSelect) {
        onFileSelect([...newFiles, ...duplicates])
      }

      // Log for debugging
      console.log('New files:', newFiles)
      console.log('Duplicates:', duplicates)
      console.log('All selected files:', [...selectedFiles, ...newFiles])
    },
    [getFileError, onFileSelect, transformFile, selectedFiles]
  )

  /**
   * Clears all selected files and resets error state.
   */
  const clearFiles = useCallback(() => {
    selectedFiles.forEach(file => {
      return URL.revokeObjectURL(file.preview)
    })

    setSelectedFiles([])
    setHasError(null)
    setHasWarning(null)
  }, [selectedFiles])

  /**
   * Removes a specified file from the selected files list and revokes its preview URL.
   *
   * @param {FileWithPreview} fileToRemove - The file to be removed.
   */
  const removeFile = useCallback((fileToRemove: FileWithPreview) => {
    setSelectedFiles(currentFiles => {
      return currentFiles.filter(file => {
        return file.id !== fileToRemove.id
      })
    })

    URL.revokeObjectURL(fileToRemove.preview)
    setHasWarning(null)
  }, [])

  return {
    selectedFiles,
    error: hasError,
    warning: hasWarning,
    handleFiles,
    clearFiles,
    removeFile
  }
}
