'use client'

import { useCookieConsent } from '../../hooks/useCookieConsent'
import { Button } from '../Button/Button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../Card'

import { CookieOption } from './CookieOption'

/**
 * Component to manage and display cookie consent settings.
 *
 * Utilizes the useCookieConsent hook to load, display, and update cookie preferences.
 * Renders a loading state while preferences are being fetched.
 *
 * @returns {JSX.Element} The rendered component.
 */
export function CookieConsent() {
  const { preferences, handlePreferenceChange, isLoading } = useCookieConsent()

  if (isLoading) {
    return <div>Loading preferences...</div>
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Cookie Settings</CardTitle>
        <CardDescription>Manage your cookie settings here.</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-6">
        <CookieOption
          id="necessary"
          title="Strictly Necessary"
          description="These cookies are essential in order to use the website and use its features."
          isChecked={preferences.necessary}
          onChange={value => {
            return handlePreferenceChange('necessary', value)
          }}
        />
        <CookieOption
          id="functional"
          title="Functional Cookies"
          description="These cookies allow the website to provide personalized functionality."
          isChecked={preferences.functional}
          onChange={value => {
            return handlePreferenceChange('functional', value)
          }}
        />
        <CookieOption
          id="performance"
          title="Performance Cookies"
          description="These cookies help to improve the performance of the website."
          isChecked={preferences.performance}
          onChange={value => {
            return handlePreferenceChange('performance', value)
          }}
        />
      </CardContent>
      <CardFooter>
        <Button variant="outline" className="w-full">
          Save preferences
        </Button>
      </CardFooter>
    </Card>
  )
}
