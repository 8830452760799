'use client'

import type { GroupBase, SelectComponentsConfig } from 'react-select'

/**
 * Generates default components for the Select component based on the provided variant.
 *
 * @param {('default' | 'naked' | 'modern' | 'shadcn' | 'minimal')} variant - The variant of the Select component.
 * @returns {SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>} The default components configuration object.
 */
export const getDefaultComponents = (
  variant: 'default' | 'naked' | 'modern' | 'shadcn' | 'minimal'
): SelectComponentsConfig<unknown, boolean, GroupBase<unknown>> => {
  let defaultComponents: SelectComponentsConfig<unknown, boolean, GroupBase<unknown>> = {}

  if (variant === 'naked') {
    defaultComponents = {
      DropdownIndicator: () => {
        return null
      },
      IndicatorSeparator: () => {
        return null
      }
    }
  }

  return defaultComponents
}
