'use client'

import { useState } from 'react'

import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '~/UI-Temp/src/utils/cn'
import { getMinimumDate } from '~/utils/getMinimumDate'

import { Button } from '../../UI/Button'
import { Calendar } from '../../UI/Calendar'
import { Popover, PopoverContent, PopoverTrigger } from '../../UI/Popover'

type DatePickerProps = {
  selected?: Date | null
  onSelect?: (date: Date | null) => void
  fromDate?: Date
  toDate?: Date
  closeOnSelect?: boolean
  className?: string
}

export function DatePicker({
  selected,
  onSelect,
  fromDate = getMinimumDate(),
  toDate,
  closeOnSelect = true,
  className
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleDateSelection = (date: Date | undefined) => {
    if (onSelect) {
      onSelect(date || null)
    }

    if (closeOnSelect) {
      setIsOpen(false)
    }
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'w-full justify-start text-left font-normal',
            !selected && 'text-muted-foreground',
            className
          )}
        >
          {selected ? format(selected, 'PPP') : 'Select a date'}
          <CalendarIcon className="ml-auto size-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={selected}
          onSelect={handleDateSelection}
          fromDate={fromDate}
          toDate={toDate}
          initialFocus
          disabled={{ before: fromDate }}
        />
      </PopoverContent>
    </Popover>
  )
}
