'use client'

import { forwardRef } from 'react'

import { Dialog } from '@headlessui/react'
import { MdClose } from 'react-icons/md'

type ModalLayoutProps = {
  children: React.ReactNode
  onClose: () => void
  size?: 'small' | 'medium' | 'large'
}

export const ModalLayout = forwardRef<HTMLDivElement, ModalLayoutProps>(
  ({ children, onClose, size = 'medium' }, ref) => {
    console.log('ModalLayout rendered with size:', size)

    const sizeClasses = {
      small: 'w-1/4 h-1/2',
      medium: 'w-1/2 h-3/4',
      large: 'w-3/4 h-5/6'
    }

    const modalClasses = `
      fixed z-40 
      left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 
      bg-white shadow-lg rounded-lg 
      flex flex-col overflow-hidden
      ${sizeClasses[size]}
    `

    console.log('Applied modal classes:', modalClasses)

    return (
      <Dialog open={true} onClose={onClose} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className={modalClasses} ref={ref}>
          <button
            type="button"
            className="absolute right-4 top-4 z-10 rounded-full bg-white p-1"
            onClick={onClose}
          >
            <MdClose className="size-6" />
          </button>
          <div className="grow overflow-y-auto p-6">{children}</div>
        </div>
      </Dialog>
    )
  }
)

ModalLayout.displayName = 'ModalLayout'
