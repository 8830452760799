import type { HTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = ''

const cardTitleVariants = cva(baseClasses, {
  variants: {
    variant: {
      default: 'text-2xl font-semibold leading-none tracking-tight',
      newYork: 'font-semibold leading-none tracking-tight'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

export type CardTitleProps = HTMLAttributes<HTMLHeadingElement> &
  VariantProps<typeof cardTitleVariants> & {
    children: ReactNode
  }

export const CardTitle = forwardRef<HTMLHeadingElement, CardTitleProps>(
  ({ variant = 'default', className, children, ...props }, ref) => {
    const combinedClasses = cn(cardTitleVariants({ variant }), className)

    return (
      <h3 ref={ref} className={combinedClasses} {...props}>
        {children}
      </h3>
    )
  }
)

CardTitle.displayName = 'CardTitle'
