'use client'

import type { PropsWithChildren } from 'react'

import { Text } from '../../UI/Text'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../UI/Tooltip/Tooltip'

type ActionTooltipProps = PropsWithChildren & {
  className?: string
  side?: 'top' | 'bottom' | 'left' | 'right'
  align?: 'start' | 'center' | 'end'
  label: string
}

/**
 * A tooltip component for displaying action labels.
 *
 * @param {Object} props - The props for the ActionTooltip component.
 * @param {ReactNode} props.children - The child elements to be wrapped by the tooltip trigger.
 * @param {string} [props.className] - Additional CSS classes to apply to the tooltip content.
 * @param {"top" | "bottom" | "left" | "right"} [props.side="top"] - The side of the tooltip relative to the trigger element.
 * @param {"start" | "center" | "end"} [props.align="center"] - The alignment of the tooltip content relative to the trigger element.
 * @param {string} props.label - The label text to display in the tooltip.
 *
 * @returns {JSX.Element} The rendered ActionTooltip component.
 *
 * @example
 * // Basic usage
 * <ActionTooltip label="Edit">
 *   <button>Edit</button>
 * </ActionTooltip>
 *
 * @example
 * // With custom side and alignment
 * <ActionTooltip label="Delete" side="right" align="start">
 *   <button>Delete</button>
 * </ActionTooltip>
 */
export const ActionTooltip = (props: ActionTooltipProps) => {
  const { children, className, side = 'top', align = 'center', label } = props

  return (
    <TooltipProvider>
      <Tooltip delayDuration={50}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent side={side} align={align} className={className}>
          <Text className="text-sm font-semibold capitalize">{label.toLowerCase()}</Text>
          <div className="text-red-"></div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
