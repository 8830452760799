import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cn } from '~/UI-Temp/src/utils/cn'

export const Table = forwardRef<HTMLTableElement, HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div className="relative w-full overflow-auto">
        <table ref={ref} className={cn('w-full caption-bottom text-sm', className)} {...props} />
      </div>
    )
  }
)

Table.displayName = 'Table'
