'use client'

import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { cn } from '~/UI-Temp/src/utils/cn'

import { useFormField } from './useFormField'

export const FormDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField()

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn('text-sm text-muted-foreground', className)}
      {...props}
    />
  )
})
FormDescription.displayName = 'FormDescription'
