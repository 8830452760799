'use client'

import { useLayoutEffect } from 'react'

/**
 * Custom React hook that prevents scrolling on the body of the page.
 *
 * This hook sets the body's overflow CSS property to "hidden" to lock scrolling,
 * and restores the original overflow value when the component using this hook unmounts.
 * This is typically used to lock the background scroll when a modal or overlay is open.
 *
 * @example
 * const Component = () => {
 *   useLockBody();
 *   return <div>Modal content here</div>;
 * };
 */
export function useLockBody() {
  useLayoutEffect((): (() => void) => {
    const originalStyle: string = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = 'hidden'

    return () => {
      return (document.body.style.overflow = originalStyle)
    }
  }, [])
}
