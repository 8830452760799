'use client'

import * as React from 'react'

import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from '~/UI-Temp/src/utils/cn'

/**
 * `Tabs` component serves as the root. It doesn't need to be directly styled or accessed,
 * so it's simply re-exported from Radix without changes.
 */
const Tabs = TabsPrimitive.Root

/**
 * `TabsList` is a forwardRef component that renders the tab list.
 * It combines provided classNames with default styles for appearance.
 *
 * @param {Object} props - Component props including `className` and rest.
 * @param {React.Ref} ref - Ref forwarded to the DOM element.
 * @returns {JSX.Element} The rendered tab list component.
 */
const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        'inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground',
        className
      )}
      {...props}
    />
  )
})
TabsList.displayName = TabsPrimitive.List.displayName

/**
 * `TabsTrigger` is a forwardRef component that renders each tab trigger (button).
 * It applies custom and state-based styling for active/inactive states.
 *
 * @param {Object} props - Component props including `className` and rest.
 * @param {React.Ref} ref - Ref forwarded to the DOM element.
 * @returns {JSX.Element} The rendered tab trigger component.
 */
const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-state=active:bg-background data-state=active:text-foreground data-state=active:shadow-sm',
        className
      )}
      {...props}
    />
  )
})
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

/**
 * `TabsContent` is a forwardRef component that renders the content of the selected tab.
 * It supports focus management and can be styled externally.
 *
 * @param {Object} props - Component props including `className` and rest.
 * @param {React.Ref} ref - Ref forwarded to the DOM element.
 * @returns {JSX.Element} The rendered content of the active tab.
 */
const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.Content
      ref={ref}
      className={cn(
        'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        className
      )}
      {...props}
    />
  )
})
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
