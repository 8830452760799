'use client'

export { Select as ReactSelect } from './ReactSelect'
export type { OptionType } from './ReactSelect'
export {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue
} from './Select'
export { getCustomStyles } from './getCustomStyles'
export { getDefaultComponents } from './getDefaultComponents'
export { components } from 'react-select'
export type { OptionProps } from 'react-select'
