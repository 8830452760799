'use client'

import type { ComponentPropsWithoutRef, ElementRef, ReactNode } from 'react'
import { forwardRef } from 'react'

import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = 'relative overflow-hidden'

const sizes: Record<string, string> = {
  small: 'h-6 w-6',
  medium: 'h-10 w-10',
  large: 'h-16 w-16'
}

const scrollAreaVariants = cva(baseClasses, {
  variants: {
    size: sizes
  },
  defaultVariants: {
    size: 'medium'
  }
})

const ScrollBar = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>((props, ref) => {
  const { className, orientation = 'vertical', ...otherProps } = props

  return (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        'flex touch-none select-none transition-colors',
        orientation === 'vertical' && 'h-full w-2.5 border-l border-l-transparent p-[1px]',
        orientation === 'horizontal' && 'h-2.5 border-t border-t-transparent p-[1px]',
        className
      )}
      {...otherProps}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-border" />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  )
})
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

type ScrollAreaProps = {
  children: ReactNode
  className?: string
} & VariantProps<typeof scrollAreaVariants>

/**
 * ScrollArea Component
 *
 * @component
 * @param {ScrollAreaProps} props - The component props.
 * @returns {JSX.Element} The ScrollArea component.
 */
const ScrollArea = forwardRef<ElementRef<typeof ScrollAreaPrimitive.Root>, ScrollAreaProps>(
  (props, ref) => {
    const { className = '', children, size = 'medium', ...otherProps } = props
    const combinedClasses = cn(scrollAreaVariants({ size }), className)

    return (
      <ScrollAreaPrimitive.Root ref={ref} className={combinedClasses} {...otherProps}>
        <ScrollAreaPrimitive.Viewport className="size-full rounded-[inherit]">
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    )
  }
)
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

export { ScrollArea, ScrollBar }
