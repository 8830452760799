import type { LabelHTMLAttributes, ReactNode } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Text } from '../../UI/Text'

const baseClasses = 'mb-1 text-sm'

const labelVariants = cva(baseClasses, {
  variants: {
    color: {
      black: 'text-black',
      gray: 'text-gray-500'
    },
    size: {
      sm: 'text-sm',
      md: 'text-md'
    }
  },
  defaultVariants: {
    color: 'black',
    size: 'sm'
  }
})

export type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  children: ReactNode
  isRequired?: boolean
} & VariantProps<typeof labelVariants>

/**
 * A customizable label component.
 *
 * @param {Object} props - The props for the Label component.
 * @param {ReactNode} props.children - The content to be rendered inside the label.
 * @param {boolean} [props.isRequired=false] - Indicates whether the label is required.
 * @param {string} [props.className=''] - Additional CSS classes to apply to the label.
 * @param {'black' | 'gray'} [props.color='black'] - The color variant of the label.
 * @param {'sm' | 'md'} [props.size='sm'] - The size variant of the label.
 * @param {LabelHTMLAttributes<HTMLLabelElement>} props.otherProps - Additional props to pass to the underlying label element.
 *
 * @returns {JSX.Element} The rendered Label component.
 *
 * @example
 * // Basic usage
 * <Label>Name</Label>
 *
 * @example
 * // With required indicator
 * <Label isRequired>Email</Label>
 *
 * @example
 * // With custom color and size
 * <Label color="gray" size="md">Address</Label>
 */
export const Label = (props: LabelProps) => {
  const {
    children,
    isRequired = false,
    className = '',
    color = 'black',
    size = 'sm',
    ...otherProps
  } = props

  const combinedClasses = cn(labelVariants({ color, size }), className)

  return (
    <label className={combinedClasses} {...otherProps}>
      {isRequired && (
        <Text as="span" className="pr-1 text-red-500">
          *
        </Text>
      )}
      {children}
    </label>
  )
}
