'use client'

import type { ReactNode } from 'react'
import { forwardRef, useState } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Button } from '..'

const tabListBaseClasses = 'flex border-b'
const tabContentBaseClasses = 'p-4'

export const tabVariants = cva([tabListBaseClasses, tabContentBaseClasses], {
  variants: {
    tabListVariant: {
      default: 'border-gray-200'
      // Additional variants like 'dark': "border-gray-700", etc.
    },
    tabContentVariant: {
      default: 'text-gray-600'
      // Additional variants
    }
  },
  defaultVariants: {
    tabListVariant: 'default',
    tabContentVariant: 'default'
  }
})

type TabItem = {
  label: string
  content: ReactNode
}

export type TabsProps = VariantProps<typeof tabVariants> & {
  items: TabItem[]
}

/**
 * Tabs component to render tabbed content.
 *
 * @param {TabsProps} props - The props for the component.
 * @param {React.Ref<HTMLDivElement>} ref - Ref forwarded to the container element.
 * @returns {React.ReactElement} - A React element representing the tab component.
 */
export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ items, tabListVariant, tabContentVariant, ...otherProps }, ref) => {
    const [activeTab, setActiveTab] = useState(0)
    const tabListClasses = tabVariants({ tabListVariant })
    const tabContentClasses = tabVariants({ tabContentVariant })

    return (
      <div ref={ref} {...otherProps}>
        <div className={tabListClasses}>
          {items.map((item, index) => {
            return (
              <Button
                key={index}
                className={cn(
                  'py-2 px-4 text-sm font-medium',
                  index === activeTab ? 'border-b-2 border-blue-500' : 'text-gray-500'
                )}
                onClick={() => {
                  return setActiveTab(index)
                }}
              >
                {item.label}
              </Button>
            )
          })}
        </div>
        <div className={tabContentClasses}>{items[activeTab].content}</div>
      </div>
    )
  }
)

Tabs.displayName = 'Tabs'
