'use client'

import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'

import * as SwitchPrimitives from '@radix-ui/react-switch'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses =
  'peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50'

const switchVariants = cva(baseClasses, {
  variants: {
    checkedState: {
      primary: 'data-[state=checked]:bg-primary',
      secondary: 'data-[state=checked]:bg-secondary'
    },
    uncheckedState: {
      input: 'data-[state=unchecked]:bg-input',
      custom: 'data-[state=unchecked]:bg-custom'
    }
  },
  defaultVariants: {
    checkedState: 'primary',
    uncheckedState: 'input'
  }
})

type SwitchProps = ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
  VariantProps<typeof switchVariants>

export const Switch = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
  (props, ref) => {
    const { className, checkedState = 'primary', uncheckedState = 'input', ...otherProps } = props

    const combinedClasses = cn(switchVariants({ checkedState, uncheckedState }), className)

    return (
      <SwitchPrimitives.Root ref={ref} className={combinedClasses} {...otherProps}>
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
          )}
        />
      </SwitchPrimitives.Root>
    )
  }
)

Switch.displayName = SwitchPrimitives.Root.displayName
