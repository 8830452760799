'use client'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { Avatar, AvatarFallback, AvatarImage } from './Base'

// add things like md: lg: etc. to the sizes(?)
const sizes: Record<string, string> = {
  small: 'h-6 w-6',
  medium: 'h-10 w-10',
  large: 'h-16 w-16'
}

const baseClasses = 'relative flex shrink-0 overflow-hidden rounded-full'

const avatarVariants = cva(baseClasses, {
  variants: {
    size: sizes
  },
  defaultVariants: {
    size: 'medium'
  }
})

type UserAvatarProps = {
  username: string
  avatarUrl?: string
  className?: string
} & VariantProps<typeof avatarVariants>

/**
 * UserAvatar Component
 *
 * @component
 * @param {UserAvatarProps} props - The component props.
 * @returns {JSX.Element} The UserAvatar component.
 */
export const UserAvatar = (props: UserAvatarProps) => {
  const { username, avatarUrl, size = 'medium', className = '' } = props

  const getInitials = () => {
    return username
      .split(' ')
      .map(n => {
        return n[0]
      })
      .join('')
  }

  const combinedClasses = cn(avatarVariants({ size }), className)

  return (
    <Avatar className={combinedClasses}>
      {avatarUrl ? (
        <AvatarImage src={avatarUrl} alt={username} />
      ) : (
        <AvatarFallback>{getInitials()}</AvatarFallback>
      )}
    </Avatar>
  )
}
