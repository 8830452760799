'use client'

import type { CSSProperties, ComponentType, SyntheticEvent } from 'react'
import { useEffect, useState, useCallback, useMemo } from 'react'

import LazyLoad from 'react-lazyload'

/**
 * Checks if the environment is Next.js by verifying the presence of `window.__NEXT_DATA__`.
 *
 * @returns {boolean} `true` if running in a Next.js environment, otherwise `false`.
 */
const isNextJS = (): boolean => {
  return typeof window !== 'undefined' && !!(window as any).__NEXT_DATA__
}

/**
 * Supported layout modes for the Image component.
 */
type Layout = 'fixed' | 'intrinsic' | 'responsive' | 'fill'

/**
 * Supported loader types for image processing services.
 */
type LoaderType = 'cloudflare' | 'cloudinary' | 'imagekit'

/**
 * Configuration for different image loaders.
 */
export type LoaderConfig = {
  type: LoaderType
  config: {
    cloudflare?: {
      zoneId: string
    }
    cloudinary?: {
      cloudName: string
    }
    imagekit?: {
      imagekitId: string
    }
  }
}

/**
 * Props for the Image component.
 */
export type ImageProps = {
  /** Source URL of the image. */
  src: string
  /** Alternative text for the image. */
  alt: string
  /** Width of the image in pixels. */
  width?: number
  /** Height of the image in pixels. */
  height?: number
  /** Source URL for the placeholder image displayed during lazy loading. */
  placeholderSrc?: string
  /** Source URL for the fallback image in case of an error. */
  fallbackSrc?: string
  /** Additional CSS classes for styling. */
  className?: string
  /** Indicates if the image should be prioritized during loading. */
  priority?: boolean
  /** Defines image sizes for responsive images. */
  sizes?: string
  /** Layout mode of the image. */
  layout?: Layout
  /** Quality of the image (1-100). */
  quality?: number
  /** Configuration for the image loader. */
  loaderConfig: LoaderConfig
  /** Inline styles for the image. */
  style?: CSSProperties
  /** Callback when image loading is complete. */
  onLoadingComplete?: (img: HTMLImageElement) => void
  /** Callback when the image is successfully loaded. */
  onLoad?: (event: SyntheticEvent<HTMLImageElement>) => void
  /** Callback when an error occurs while loading the image. */
  onError?: (event: SyntheticEvent<HTMLImageElement>) => void
  /** Specifies the loading behavior ('lazy' or 'eager'). */
  loading?: 'lazy' | 'eager'
  /** Data URL for a blurred version of the image used as a placeholder. */
  blurDataURL?: string
  /** If `true`, disables the default image optimization. */
  unoptimized?: boolean
  /** If `true`, the image will stretch to fill its parent container. */
  fill?: boolean
}

/**
 * Generates a Cloudflare Image URL with query parameters.
 *
 * @param {string} src - The source path of the image.
 * @param {number} [width] - Desired width of the image.
 * @param {number} [height] - Desired height of the image.
 * @param {number} [quality] - Desired quality of the image.
 * @param {LoaderConfig['config']['cloudflare']} [config] - Cloudflare specific configuration.
 * @returns {string} - The formatted Cloudflare Image URL.
 */
const cloudflareLoader = (
  src: string,
  width?: number,
  height?: number,
  quality?: number,
  config?: LoaderConfig['config']['cloudflare']
): string => {
  const params = new URLSearchParams()

  if (width) {
    params.append('width', width.toString())
  }

  if (height) {
    params.append('height', height.toString())
  }

  if (quality) {
    params.append('quality', quality.toString())
  }

  return `https://${config?.zoneId}.cloudflareimages.com/${src}?${params.toString()}`
}

/**
 * Generates a Cloudinary Image URL with transformation parameters.
 *
 * @param {string} src - The source path of the image.
 * @param {number} [width] - Desired width of the image.
 * @param {number} [height] - Desired height of the image.
 * @param {number} [quality] - Desired quality of the image.
 * @param {LoaderConfig['config']['cloudinary']} [config] - Cloudinary specific configuration.
 * @returns {string} - The formatted Cloudinary Image URL.
 */
const cloudinaryLoader = (
  src: string,
  width?: number,
  height?: number,
  quality?: number,
  config?: LoaderConfig['config']['cloudinary']
): string => {
  const transformations: string[] = []

  if (width) {
    transformations.push(`w_${width}`)
  }

  if (height) {
    transformations.push(`h_${height}`)
  }

  if (quality) {
    transformations.push(`q_${quality}`)
  }

  const transformationString = transformations.join(',')

  return `https://res.cloudinary.com/${config?.cloudName}/image/upload/${transformationString}/${src}`
}

/**
 * Generates an ImageKit Image URL with transformation parameters.
 *
 * @param {string} src - The source path of the image.
 * @param {number} [width] - Desired width of the image.
 * @param {number} [height] - Desired height of the image.
 * @param {number} [quality] - Desired quality of the image.
 * @param {LoaderConfig['config']['imagekit']} [config] - ImageKit specific configuration.
 * @returns {string} - The formatted ImageKit Image URL.
 */
const imageKitLoader = (
  src: string,
  width?: number,
  height?: number,
  quality?: number,
  config?: LoaderConfig['config']['imagekit']
): string => {
  const transformations: string[] = []

  if (width) {
    transformations.push(`tr:w-${width}`)
  }

  if (height) {
    transformations.push(`tr:h-${height}`)
  }

  if (quality) {
    transformations.push(`tr:q-${quality}`)
  }

  const transformationString = transformations.join(',')

  return `https://ik.imagekit.io/${config?.imagekitId}/${src}?${transformationString}`
}

/**
 * CustomImage is a component that handles image rendering with support for various loaders,
 * lazy loading, placeholders, and fallback images.
 *
 * @param {ImageProps} props - The props for the CustomImage component.
 * @returns {JSX.Element} - The rendered image element.
 */
export function CustomImage({
  src,
  alt,
  width,
  height,
  placeholderSrc = '/assets/images/placeholder/product-image-placeholder.svg',
  priority = false,
  sizes,
  layout = 'intrinsic',
  quality = 75,
  loaderConfig,
  style,
  onLoadingComplete,
  onLoad,
  onError,
  loading = 'lazy',
  blurDataURL,
  unoptimized = false,
  fill = false,
  fallbackSrc = placeholderSrc,
  className,
  ...restProps
}: ImageProps): JSX.Element {
  const [imageSrc, setImageSrc] = useState<string>(src)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  /**
   * Determines the appropriate loader function based on the loader configuration.
   *
   * @param {LoaderConfig} config - The loader configuration.
   * @returns {(src: string, width?: number, height?: number, quality?: number) => string} - The loader function.
   */
  const getLoader = useCallback(
    (
      config: LoaderConfig
    ): ((src: string, width?: number, height?: number, quality?: number) => string) => {
      switch (config.type) {
        case 'cloudinary':
          return (src, width, height, quality) => {
            return cloudinaryLoader(src, width, height, quality, config.config.cloudinary)
          }

        case 'imagekit':
          return (src, width, height, quality) => {
            return imageKitLoader(src, width, height, quality, config.config.imagekit)
          }

        case 'cloudflare':
        default:
          return (src, width, height, quality) => {
            return cloudflareLoader(src, width, height, quality, config.config.cloudflare)
          }
      }
    },
    []
  )

  const loader = useMemo(() => {
    return getLoader(loaderConfig)
  }, [getLoader, loaderConfig])

  /**
   * Computes the CSS styles for the image based on the layout prop.
   *
   * @returns {CSSProperties} - The computed styles.
   */
  const getImageDimensions = useCallback((): CSSProperties => {
    switch (layout) {
      case 'fixed':
        return { width: width || 'auto', height: height || 'auto' }
      case 'intrinsic':
      case 'responsive':
        return { width: '100%', height: 'auto' }
      case 'fill':
        return {
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover'
        }
      default:
        return { width: 'auto', height: 'auto' }
    }
  }, [layout, width, height])

  const dimensions = getImageDimensions()

  /**
   * Handles the image load event.
   *
   * @param {SyntheticEvent<HTMLImageElement, Event>} event - The load event.
   */
  const handleLoad = useCallback(
    (event: SyntheticEvent<HTMLImageElement>): void => {
      setIsLoading(false)
      setIsError(false)

      if (onLoad) {
        onLoad(event)
      }

      if (onLoadingComplete) {
        onLoadingComplete(event.currentTarget)
      }
    },
    [onLoad, onLoadingComplete]
  )

  /**
   * Handles the image error event by setting the fallback source.
   *
   * @param {SyntheticEvent<HTMLImageElement, Event>} event - The error event.
   */
  const handleError = useCallback(
    (event: SyntheticEvent<HTMLImageElement>): void => {
      if (!isError && fallbackSrc && fallbackSrc !== imageSrc) {
        setImageSrc(fallbackSrc)
        setIsError(true)
      }

      if (onError) {
        onError(event)
      }
    },
    [fallbackSrc, isError, imageSrc, onError]
  )

  useEffect(() => {
    setImageSrc(src)
    setIsLoading(true)
    setIsError(false)
  }, [src])

  return (
    <LazyLoad
      height={height}
      offset={100}
      once
      placeholder={
        <img
          src={placeholderSrc || blurDataURL}
          alt={alt}
          style={{
            ...style,
            ...dimensions,
            filter: 'blur(20px)',
            transition: 'filter 0.3s'
          }}
          aria-hidden="true"
        />
      }
    >
      <img
        {...restProps}
        className={className}
        src={unoptimized || isError ? imageSrc : loader(imageSrc, width, height, quality)}
        alt={alt}
        width={fill ? undefined : width}
        height={fill ? undefined : height}
        loading={priority ? 'eager' : loading}
        sizes={sizes}
        style={
          isLoading
            ? {
                ...style,
                ...dimensions,
                filter: 'blur(20px)',
                transition: 'filter 0.3s'
              }
            : { ...style, ...dimensions }
        }
        onLoad={handleLoad}
        onError={handleError}
        srcSet={
          unoptimized || isError
            ? imageSrc
            : `${loader(
                imageSrc,
                width ? Math.floor(width / 2) : undefined,
                height ? Math.floor(height / 2) : undefined,
                quality
              )} 1x, ${loader(imageSrc, width, height, quality)} 2x`
        }
        aria-busy={isLoading}
      />
    </LazyLoad>
  )
}

/**
 * Image component that conditionally uses Next.js's Image component if available,
 * otherwise falls back to the CustomImage component.
 *
 * @param {ImageProps} props - The props for the Image component.
 * @returns {JSX.Element} - The rendered Image component.
 */
export function Image({ ...props }: ImageProps): JSX.Element {
  const [NextImageComponent, setNextImageComponent] = useState<ComponentType<ImageProps> | null>(
    null
  )

  useEffect(() => {
    const loadNextImageComponent = async () => {
      if (isNextJS()) {
        try {
          const module = await import('next/image')
          setNextImageComponent(() => {
            return module.default as ComponentType<ImageProps>
          })
        } catch (error) {
          console.error('Failed to load Next.js Image component:', error)
        }
      }
    }
    loadNextImageComponent()
  }, [])

  if (isNextJS() && NextImageComponent) {
    return <NextImageComponent {...props} />
  }

  return <CustomImage {...props} />
}
