'use client'

import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'

import * as SliderPrimitive from '@radix-ui/react-slider'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = 'relative flex w-full touch-none select-none items-center'

const sliderVariants = cva(baseClasses, {
  variants: {
    trackBackground: {
      secondary: 'bg-secondary',
      custom: 'bg-custom' // Add your custom background classes here
    },
    thumbStyle: {
      default: 'border-2 border-primary bg-background',
      custom: 'border-custom bg-custom' // Add your custom thumb styles here
    }
  },
  defaultVariants: {
    trackBackground: 'secondary',
    thumbStyle: 'default'
  }
})

type SliderProps = ComponentPropsWithoutRef<typeof SliderPrimitive.Root> &
  VariantProps<typeof sliderVariants>

export const Slider = forwardRef<ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
  (props, ref) => {
    const {
      className = '',
      trackBackground = 'secondary',
      thumbStyle = 'default',
      ...otherProps
    } = props

    const combinedClasses = cn(sliderVariants({ trackBackground, thumbStyle }), className)

    return (
      <SliderPrimitive.Root ref={ref} className={combinedClasses} {...otherProps}>
        <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full">
          <SliderPrimitive.Range className="absolute h-full bg-primary" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block size-5 rounded-full ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
      </SliderPrimitive.Root>
    )
  }
)
Slider.displayName = SliderPrimitive.Root.displayName
