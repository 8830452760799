'use client'

import { useMounted } from './useMounted'

/**
 * Custom hook to get the origin of the current window.
 *
 * @returns {string} - The origin of the current window if the component is mounted and the window object is defined, otherwise an empty string.
 *
 * @example
 * const origin = useOrigin();
 */
export const useOrigin = () => {
  const isMounted = useMounted()

  const origin =
    typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''

  if (!isMounted.current) {
    return ''
  }

  return origin
}
