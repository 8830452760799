'use client'

import type { ReactNode } from 'react'
import { Fragment, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/solid'

import { cn } from '~/UI-Temp/src/utils/cn'

type DrawerProps = {
  heading?: string
  open: boolean
  onClose: () => void
  openFrom: 'right' | 'left'
  children: ReactNode
}

const offScreen = {
  right: 'translate-x-full',
  left: '-translate-x-full'
}

// Wrapper for Dialog.Title to fix TypeScript issue with internal Dialog.Title

// error TS4032: Property 'Title' of exported interface has or is using name 'ComponentDialogTitle' from private module '"/node_modules/.pnpm/@headlessui+react@1.7.17_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/dialog/dialog"'.

// Drawer.Title = Dialog.Title

const DrawerTitle: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <Dialog.Title as="h2">{children}</Dialog.Title>
}

/**
 * Drawer component that opens on user click.
 * @param heading - string. Shown at the top of the drawer.
 * @param open - boolean state. if true opens the drawer.
 * @param onClose - function should set the open state.
 * @param openFrom - right, left
 * @param children - react children node.
 */
export function Drawer({ heading, open, onClose, openFrom = 'right', children }: DrawerProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1000000000]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 left-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={cn(
                'fixed inset-y-0 flex max-w-full',
                openFrom === 'right' ? 'right-0' : ''
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom={offScreen[openFrom]}
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo={offScreen[openFrom]}
              >
                <Dialog.Panel className="bg-contrast h-screen w-screen max-w-lg text-left align-middle shadow-xl transition-all">
                  <header
                    className={cn(
                      'h-nav border-primary sticky top-0 flex items-center border-b border-solid px-6 sm:px-8 md:px-14',
                      heading ? 'justify-between' : 'justify-end'
                    )}
                  >
                    <button
                      type="button"
                      className="text-brand-100 hover:text-brand-100/50 -m-4 p-4 transition"
                      onClick={onClose}
                      title="Close panel"
                    >
                      <XCircleIcon aria-label="Close panel" />
                    </button>
                  </header>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

/* Use for associating arialabelledby with the title */
Drawer.Title = DrawerTitle

export function useDrawer(openDefault = false) {
  const [isOpen, setIsOpen] = useState(openDefault)

  function openDrawer() {
    setIsOpen(true)
  }

  function closeDrawer() {
    setIsOpen(false)
  }

  return {
    isOpen,
    openDrawer,
    closeDrawer
  }
}
