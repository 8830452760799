import type { GroupBase, StylesConfig } from 'react-select'

/**
 * Utility function to get the value of a CSS variable.
 * @param {string} variable - The CSS variable name (e.g., '--foreground').
 * @returns {string} The computed value of the CSS variable.
 */
const getCSSVariable = (variable: string): string => {
  if (typeof window !== 'undefined') {
    return getComputedStyle(document.documentElement).getPropertyValue(variable).trim()
  }

  return '' // Return an empty string if window is undefined (SSR)
}

/**
 * Generates custom styles for the "naked" variant of react-select.
 * @returns {StylesConfig} The custom styles configuration object for the "naked" variant.
 */
export const getNakedStyles = (
  hasError: boolean
): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  return {
    control: provided => {
      return {
        ...provided,
        overflow: 'hidden',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: `hsl(${getCSSVariable('--input')})`,
        '&:hover': {
          border: 'none',
          boxShadow: 'none'
        }
      }
    },
    singleValue: provided => {
      return {
        ...provided,
        color: `hsl(${hasError ? getCSSVariable('--destructive') : getCSSVariable('--foreground')})`
      }
    },
    multiValue: provided => {
      return {
        ...provided,
        backgroundColor: `hsl(${
          hasError
            ? getCSSVariable('--destructive-foreground')
            : getCSSVariable('--primary-foreground')
        })`
      }
    },
    menu: provided => {
      return {
        ...provided,
        boxShadow: 'none',
        border: 'none',
        borderRadius: 'none',
        marginTop: 0,
        zIndex: 9999,
        backgroundColor: `hsl(${getCSSVariable('--popover')})`
      }
    },
    menuList: provided => {
      return {
        ...provided,
        padding: 0
      }
    },
    option: provided => {
      return {
        ...provided,
        padding: '0.5rem 1rem',
        backgroundColor: 'transparent',
        color: `hsl(${getCSSVariable('--text')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--muted')})`
        }
      }
    },
    multiValueLabel: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--foreground')})`
      }
    },
    multiValueRemove: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--foreground')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--muted')})`,
          color: `hsl(${getCSSVariable('--foreground')})`
        }
      }
    }
  }
}

/**
 * Generates custom styles for the "default" variant of react-select.
 * @returns {StylesConfig} The custom styles configuration object for the "default" variant.
 */
export const getDefaultStyles = (
  hasError: boolean
): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  return {
    control: (base, state) => {
      return {
        ...base,
        overflow: 'hidden',
        backgroundColor: `hsl(${getCSSVariable('--input')})`,
        borderColor: hasError
          ? `hsl(${getCSSVariable('--destructive')})`
          : state.isFocused
            ? `hsl(${getCSSVariable('--foreground')})`
            : `hsl(${getCSSVariable('--border')})`,
        boxShadow: hasError ? `0 0 0 1px hsl(${getCSSVariable('--destructive')})` : 'none',
        '&:hover': {
          borderColor: hasError
            ? `hsl(${getCSSVariable('--destructive')})`
            : state.isFocused
              ? `hsl(${getCSSVariable('--foreground')})`
              : `hsl(${getCSSVariable('--border')})`
        },
        height: '40px',
        minHeight: '40px',
        '&:focus': {
          boxShadow: hasError
            ? `0 0 0 1px hsl(${getCSSVariable('--destructive')})`
            : `0 0 0 3px hsl(${getCSSVariable('--accent')})`
        }
      }
    },
    input: base => {
      return {
        ...base,
        color: `hsl(${getCSSVariable('--foreground')})`,
        boxShadow: 'none'
      }
    },
    menu: base => {
      return {
        ...base,
        zIndex: 9999,
        backgroundColor: `hsl(${getCSSVariable('--popover')})`
      }
    },
    option: (base, state) => {
      const { isDisabled } = state

      return {
        ...base,
        backgroundColor: state.isFocused
          ? `hsl(${getCSSVariable('--muted')})`
          : `hsl(${getCSSVariable('--background')})`,
        color: isDisabled
          ? `hsl(${getCSSVariable('--border')})`
          : state.isSelected
            ? `hsl(${getCSSVariable('--foreground')})`
            : `hsl(${getCSSVariable('--text')})`,
        cursor: isDisabled ? 'not-allowed' : 'default',
        '&:hover': {
          backgroundColor: isDisabled
            ? `hsl(${getCSSVariable('--background')})`
            : `hsl(${getCSSVariable('--muted')})`
        }
      }
    },
    multiValue: base => {
      return {
        ...base,
        backgroundColor: hasError
          ? `hsl(${getCSSVariable('--destructive-foreground')})`
          : `hsl(${getCSSVariable('--primary-foreground')})`
      }
    },
    multiValueLabel: base => {
      return {
        ...base,
        color: hasError
          ? `hsl(${getCSSVariable('--destructive')})`
          : `hsl(${getCSSVariable('--text')})`
      }
    },
    multiValueRemove: base => {
      return {
        ...base,
        ':hover': {
          backgroundColor: hasError
            ? `hsl(${getCSSVariable('--destructive-foreground')})`
            : `hsl(${getCSSVariable('--muted')})`,
          color: hasError
            ? `hsl(${getCSSVariable('--destructive')})`
            : `hsl(${getCSSVariable('--foreground')})`
        }
      }
    }
  }
}

/**
 * Generates custom styles for the "modern" variant of react-select.
 * @returns {StylesConfig} The custom styles configuration object for the "modern" variant.
 */
export const getModernStyles = (
  hasError = false
): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  return {
    control: provided => {
      return {
        ...provided,
        overflow: 'hidden',
        backgroundColor: `hsl(${getCSSVariable('--input')})`,
        border: hasError
          ? `2px solid hsl(${getCSSVariable('--destructive')})`
          : `2px solid hsl(${getCSSVariable('--border')})`,
        borderRadius: '0.75rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        '&:hover': {
          borderColor: hasError
            ? `hsl(${getCSSVariable('--destructive')})`
            : `hsl(${getCSSVariable('--border')})`
        },
        '&:focus': {
          boxShadow: `0 0 0 3px hsl(${getCSSVariable('--focus-border')})`
        }
      }
    },
    singleValue: provided => {
      return {
        ...provided,
        color: hasError
          ? `hsl(${getCSSVariable('--destructive')})`
          : `hsl(${getCSSVariable('--foreground')})`
      }
    },
    input: base => {
      return {
        ...base,
        color: `hsl(${getCSSVariable('--foreground')})`
      }
    },
    multiValue: provided => {
      return {
        ...provided,
        backgroundColor: hasError
          ? `hsl(${getCSSVariable('--destructive-foreground')})`
          : `hsl(${getCSSVariable('--primary-foreground')})`
      }
    },
    menu: provided => {
      return {
        ...provided,
        borderRadius: '0.5rem',
        marginTop: '0.25rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 9999,
        backgroundColor: `hsl(${getCSSVariable('--popover')})`
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused
          ? `hsl(${getCSSVariable('--primary-foreground')})`
          : `hsl(${getCSSVariable('--background')})`,
        color: state.isFocused
          ? `hsl(${getCSSVariable('--primary')})`
          : `hsl(${getCSSVariable('--text')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--primary-foreground')})`,
          color: `hsl(${getCSSVariable('--primary')})`
        }
      }
    },
    multiValueLabel: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--foreground')})`
      }
    },
    multiValueRemove: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--foreground')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--primary')})`,
          color: `hsl(${getCSSVariable('--foreground')})`
        }
      }
    }
  }
}

/**
 * Generates custom styles for the "shadcn" variant of react-select.
 * @returns {StylesConfig} The custom styles configuration object for the "shadcn" variant.
 */
export const getShadcnStyles = (
  hasError: boolean
): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  return {
    control: (provided, state) => {
      return {
        ...provided,
        overflow: 'hidden',
        backgroundColor: `hsl(${getCSSVariable('--input')})`,
        borderRadius: '0.375rem',
        borderWidth: '1px',
        borderColor: state.isFocused
          ? `hsl(${getCSSVariable('--primary')})`
          : `hsl(${getCSSVariable('--border')})`,
        boxShadow: state.isFocused ? `0 0 0 2px hsl(${getCSSVariable('--primary')})` : 'none',
        '&:hover': {
          borderColor: state.isFocused
            ? `hsl(${getCSSVariable('--primary')})`
            : `hsl(${getCSSVariable('--border')})`
        }
      }
    },
    singleValue: provided => {
      return {
        ...provided,
        color: hasError
          ? `hsl(${getCSSVariable('--destructive')})`
          : `hsl(${getCSSVariable('--foreground')})`
      }
    },
    input: base => {
      return {
        ...base,
        color: `hsl(${getCSSVariable('--foreground')})`
      }
    },
    multiValue: provided => {
      return {
        ...provided,
        backgroundColor: hasError
          ? `hsl(${getCSSVariable('--destructive-foreground')})`
          : `hsl(${getCSSVariable('--primary-foreground')})`
      }
    },
    menu: provided => {
      return {
        ...provided,
        borderRadius: '0.375rem',
        marginTop: '0.25rem',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        zIndex: 9999,
        backgroundColor: `hsl(${getCSSVariable('--popover')})`
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused
          ? `hsl(${getCSSVariable('--primary-foreground')})`
          : `hsl(${getCSSVariable('--background')})`,
        color: state.isFocused
          ? `hsl(${getCSSVariable('--primary')})`
          : `hsl(${getCSSVariable('--text')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--primary-foreground')})`,
          color: `hsl(${getCSSVariable('--primary')})`
        }
      }
    },
    multiValueLabel: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--primary')})`
      }
    },
    multiValueRemove: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--primary')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--primary')})`,
          color: `hsl(${getCSSVariable('--foreground')})`
        }
      }
    }
  }
}

/**
 * Generates custom styles for the "minimal" variant of react-select.
 * @returns {StylesConfig} The custom styles configuration object for the "minimal" variant.
 */
export const getMinimalStyles = (
  hasError: boolean
): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  return {
    control: provided => {
      return {
        ...provided,
        overflow: 'hidden',
        backgroundColor: `hsl(${getCSSVariable('--input')})`,
        borderRadius: '0.25rem',
        borderWidth: '1px',
        borderColor: `hsl(${getCSSVariable('--border')})`,
        boxShadow: 'none',
        '&:hover': {
          borderColor: `hsl(${getCSSVariable('--border')})`
        }
      }
    },
    singleValue: provided => {
      return {
        ...provided,
        color: hasError
          ? `hsl(${getCSSVariable('--destructive')})`
          : `hsl(${getCSSVariable('--foreground')})`
      }
    },
    input: base => {
      return {
        ...base,
        color: `hsl(${getCSSVariable('--foreground')})`
      }
    },
    multiValue: provided => {
      return {
        ...provided,
        backgroundColor: hasError
          ? `hsl(${getCSSVariable('--destructive-foreground')})`
          : `hsl(${getCSSVariable('--primary-foreground')})`
      }
    },
    menu: provided => {
      return {
        ...provided,
        borderRadius: '0.25rem',
        marginTop: '0.25rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 9999,
        backgroundColor: `hsl(${getCSSVariable('--popover')})`
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused
          ? `hsl(${getCSSVariable('--muted')})`
          : `hsl(${getCSSVariable('--background')})`,
        color: `hsl(${getCSSVariable('--text')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--muted')})`
        }
      }
    },
    multiValueLabel: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--text')})`
      }
    },
    multiValueRemove: provided => {
      return {
        ...provided,
        color: `hsl(${getCSSVariable('--text')})`,
        '&:hover': {
          backgroundColor: `hsl(${getCSSVariable('--muted-foreground')})`,
          color: `hsl(${getCSSVariable('--text')})`
        }
      }
    }
  }
}

/**
 * Generates custom styles for react-select based on the provided variant.
 * @param {'default' | 'naked' | 'modern' | 'shadcn' | 'minimal'} variant - The variant of the select component.
 * @returns {StylesConfig} The custom styles configuration object.
 */
export const getCustomStyles = (
  variant: 'default' | 'naked' | 'modern' | 'shadcn' | 'minimal',
  hasError: boolean
): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  switch (variant) {
    case 'naked':
      return getNakedStyles(hasError)
    case 'modern':
      return getModernStyles(hasError)
    case 'shadcn':
      return getShadcnStyles(hasError)
    case 'minimal':
      return getMinimalStyles(hasError)
    default:
      return getDefaultStyles(hasError)
  }
}
