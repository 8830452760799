'use client'

import { useLocalForage } from './useLocalForage'

type CookiePreferences = {
  necessary: boolean
  functional: boolean
  performance: boolean
}

const defaultPreferences: CookiePreferences = {
  necessary: true,
  functional: false,
  performance: false
}

type UseCookieConsentReturnType = {
  preferences: CookiePreferences
  handlePreferenceChange: (preference: keyof CookiePreferences, value: boolean) => void
  isLoading: boolean
}

/**
 * Custom hook to manage cookie consent preferences using LocalForage.
 *
 * This hook provides an interface to get and set user's cookie preferences
 * with asynchronous persistence.
 *
 * @returns {UseCookieConsentReturnType} The cookie preferences, a function to update them, and the loading state.
 */
export const useCookieConsent = (): UseCookieConsentReturnType => {
  const [preferences, setPreferences, isLoading] = useLocalForage<CookiePreferences>(
    'cookiePreferences',
    defaultPreferences
  )

  /**
   * Handles changes to cookie preferences.
   *
   * @param {keyof CookiePreferences} preference - The cookie preference to update.
   * @param {boolean} value - The new value for the preference.
   */
  const handlePreferenceChange = (preference: keyof CookiePreferences, value: boolean) => {
    setPreferences(prevPreferences => {
      return {
        ...prevPreferences,
        [preference]: value
      }
    })
  }

  const returnValue: UseCookieConsentReturnType = {
    preferences,
    handlePreferenceChange,
    isLoading
  }

  return returnValue
}
