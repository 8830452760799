import type { ElementType, HTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

/**
 * Initialize badge variants using Class Variance Authority.
 */
const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        success: 'border-transparent bg-green-100 text-green-800 hover:bg-green-200',
        warning: 'border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
        outline: 'text-foreground'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

type BadgeProps = HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants> & {
    /**
     * The type of HTML element to render.
     * Allows more semantic HTML like `span`, `p`, etc.
     */
    as?: ElementType
  }

/**
 * Badge component to display labels, tags, or other small pieces of information.
 *
 * @param {BadgeProps} props - The component props.
 * @returns {React.ReactElement} The Badge component.
 */
export function Badge(props: BadgeProps) {
  const { as: Component = 'span', className, variant, ...otherProps } = props
  const combinedClasses = cn(badgeVariants({ variant }), className)

  return <Component className={combinedClasses} {...otherProps} />
}
