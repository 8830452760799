'use client'

import type { IFuseOptions } from 'fuse.js'
import Fuse from 'fuse.js'

/**
 * Custom hook for performing a fuzzy search on an array of items.
 *
 * @template T The type of the items in the array to search.
 * @param {T[]} itemsData - The array of items to search.
 * @param {string} searchTerm - The term to search for.
 * @param {IFuseOptions<T>} options - Fuse.js options for the search.
 * @returns {T[]} - The search results if a search term is provided, otherwise the original items array.
 */
export const useFuse = <T>(itemsData: T[], searchTerm: string, options: IFuseOptions<T>): T[] => {
  const fuse = new Fuse(itemsData, options)

  return searchTerm
    ? fuse.search(searchTerm).map(result => {
        return result.item
      })
    : itemsData
}
