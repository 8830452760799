import type { HTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

const baseClasses = 'my-4'

const spacerVariants = cva(baseClasses, {
  variants: {
    axis: {
      vertical: 'my-4',
      horizontal: 'mx-4',
      both: 'm-4'
    },
    size: {
      small: 'm-2',
      medium: 'm-4',
      large: 'm-8'
    }
  },
  defaultVariants: {
    axis: 'vertical',
    size: 'medium'
  }
})

export type SpacerProps = HTMLAttributes<HTMLDivElement> & {
  className?: string
  axis?: 'vertical' | 'horizontal' | 'both'
  size?: 'small' | 'medium' | 'large'
} & VariantProps<typeof spacerVariants>

export const Spacer = (props: SpacerProps) => {
  const { className = '', axis, size } = props
  const combinedClasses = cn(spacerVariants({ axis, size }), className)

  return <div className={combinedClasses} />
}
