import type { ElementType, ReactNode } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/UI-Temp/src/utils/cn'

import { missingClass } from '../../utils/missingClass'

const baseClasses = 'whitespace-pre-wrap'

const colors: Record<string, string> = {
  default: 'inherit',
  primary: 'text-brand-300/90',
  subtle: 'text-brand-300/50',
  notice: 'text-notice',
  contrast: 'text-contrast/90'
}

const sizes: Record<string, string> = {
  lead: 'text-lead font-medium',
  copy: 'text-copy',
  fine: 'text-fine subpixel-antialiased'
}

const defaultVariants: Record<string, string> = {
  color: 'default',
  size: 'copy'
}

const textVariants = cva(baseClasses, {
  variants: {
    color: colors,
    size: sizes
  },
  defaultVariants
})

export type TextProps = {
  as?: ElementType
  className?: string
  children: ReactNode
} & VariantProps<typeof textVariants>

/**
 * A Text component that allows you to easily create text elements with various styles.
 *
 * @param {TextProps} props - The component props
 * @param {ElementType} [props.as='p'] - The element type to render the text as
 * @param {string} [props.className] - Additional class names to be added to the component
 * @param {'default'|'primary'|'subtle'|'notice'|'contrast'} [props.color='default'] - The text color
 * @param {boolean} [props.format=false] - Whether to format the text
 * @param {'lead'|'copy'|'fine'} [props.size='copy'] - The text size
 * @param {ReactNode} props.children - The children to be rendered
 * @returns {JSX.Element} - The Text component
 * @example
 * <Text color="primary" size="lead">
 *  This is a lead text element
 * </Text>
 */
export function Text(props: TextProps) {
  const {
    as: Component = 'p',
    className,
    children,
    color = 'default',
    size = 'copy',
    ...otherProps
  } = props

  const combinedClasses = cn(
    textVariants({ color, size }),
    missingClass(className, 'whitespace-') && 'whitespace-pre-wrap',
    className
  )

  return (
    <Component {...otherProps} className={combinedClasses}>
      {children}
    </Component>
  )
}
