'use client'

import type { MutableRefObject } from 'react'
import { useEffect, useRef } from 'react'

/**
 * Custom hook to observe mutations on a DOM node.
 *
 * @param {MutableRefObject<HTMLElement | null>} targetRef - The ref to the DOM node to observe.
 * @param {(mutations: MutationRecord[], observer: MutationObserver) => void} callback - Function to call when mutations are observed.
 * @param {MutationObserverInit} options - Options for the observer.
 *
 * @example
 * const targetRef = useRef(null);
 * useMutationObserver(targetRef, (mutations) => {
 *   mutations.forEach((mutation) => {
 *     console.log(mutation);
 *   });
 * }, { childList: true });
 */
export function useMutationObserver(
  targetRef: MutableRefObject<HTMLElement | null>,
  callback: (mutations: MutationRecord[], observer: MutationObserver) => void,
  options: MutationObserverInit = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true
  }
) {
  const observerRef = useRef<MutationObserver | null>(null)

  useEffect(() => {
    if (!targetRef.current) {
      return
    }

    observerRef.current = new MutationObserver(callback)

    observerRef.current.observe(targetRef.current, options)

    return () => {
      observerRef.current?.disconnect()
    }
  }, [targetRef, options, callback])
}
